import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  Container,
  SpaceBetween,
  Header,
  Select,
  FormField,
  SelectProps,
  Input,
  Alert,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { getUser } from 'src/api/auth';
import { getUserInfo, getWorkspacesForGroups } from 'src/api/permissions';
import { Workspace } from 'aws-sdk/clients/awsdatalakegladstonelambda';

export interface ConfigureEnvironmentContentProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
  errorMessage?: string;
  environmentType: SelectProps.Option;
  setEnvironmentType: any;
  group: SelectProps.Option;
  setGroup: any;
  workspace: SelectProps.Option;
  setWorkspace: any;
  clusterId: string;
  setClusterId: any;
  databaseName: string;
  setDatabaseName: any;
  workgroupName: string;
  setWorkgroupName: any;
  secretArn: string;
  setSecretArn: any;
}

export const ConfigureEnvironmentContent = (props: ConfigureEnvironmentContentProps) => {
  const ENVIRONMENT_TYPE_OPTIONS = [
    { value: 'dwcluster', label: 'Data Warehouse (Redshift)' },
    { value: 'dwserverless', label: 'Data Warehouse (Redshift Serverless)' },
  ];

  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState<string>(undefined);
  // TODO: options should not be state
  const [groupOptions, setGroupOptions] = useState([]);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);

    const user = await getUser();
    const userInfo = await getUserInfo({ userId: user });
    const groupIds = userInfo.groupIds;

    const workspacesMap = (
      await getWorkspacesForGroups({
        groupIds,
      })
    ).workspaces;
    let workspaces: Workspace[] = [];
    for (const groupId of groupIds) {
      const workspacesInGroup = workspacesMap[groupId];
      workspaces.push(...workspacesInGroup.workspaces);
    }

    setGroupOptions(groupIds.map((groupId) => ({ label: groupId, value: groupId } as SelectProps.Option)));
    setWorkspaceOptions(
      workspaces.map(
        (workspace) =>
          ({
            label: `${workspace.workspaceName} (${workspace.accountId})`,
            value: workspace.workspaceId,
          } as SelectProps.Option),
      ),
    );

    setLoading(false);
  };

  if (redirect) {
    if (redirect) return <Redirect push to={redirect} />;
  }

  return (
    <SpaceBetween size={'l'} direction={'vertical'}>
      {props.errorMessage && <Alert type={'error'}>{props.errorMessage}</Alert>}
      <SpaceBetween size={'s'} direction={'vertical'}>
        <FormField
          label={'Environment type'}
          description={
            'Select what type of environment to create. Currently, only Data Warehouse (Redshift) is supported.'
          }
        >
          <Select
            selectedOption={props.environmentType}
            options={ENVIRONMENT_TYPE_OPTIONS}
            onChange={(e) => props.setEnvironmentType(e.detail.selectedOption)}
          />
        </FormField>
        <FormField
          label={'Workspace'}
          description={'Select the workspace corresponding to the account you would like to use.'}
        >
          <Select
            selectedOption={props.workspace}
            options={workspaceOptions}
            loadingText={loading && 'Loading workspaces'}
            statusType={loading && 'loading'}
            onChange={(e) => props.setWorkspace(e.detail.selectedOption)}
          />
        </FormField>
        <FormField
          label={'Group'}
          description={
            'Select what group to add the environment to. This will correspond to a project in the DataZone portal. You can create the same environment in multiple projects.'
          }
        >
          <Select
            selectedOption={props.group}
            options={groupOptions}
            loadingText={loading && 'Loading groups'}
            statusType={loading && 'loading'}
            onChange={(e) => props.setGroup(e.detail.selectedOption)}
          />
        </FormField>
      </SpaceBetween>
      {props.environmentType?.value == 'dwcluster' && (
        <Container header={<Header variant={'h2'}>Data Warehouse environment details</Header>}>
          <SpaceBetween size={'s'} direction={'vertical'}>
            <FormField label={'Cluster'} description={'Provide a Redshift cluster name in the target account.'}>
              <Input value={props.clusterId} onChange={(e) => props.setClusterId(e.detail.value)} />
            </FormField>
            <FormField label={'Database name'} description={'The name of the database within the cluster.'}>
              <Input
                value={props.databaseName}
                placeholder={'database_name'}
                onChange={(e) => props.setDatabaseName(e.detail.value)}
              />
            </FormField>
            <FormField
              label={'Credentials secret ARN'}
              description={
                'The ARN of the secret in Secrets Manager in the cluster account containing the cluster credentials. For more info, click here.'
              }
            >
              <Input
                value={props.secretArn}
                placeholder={'arn:aws:secretsmanager:us-east-1:123412341234:secret:SecretName'}
                onChange={(e) => props.setSecretArn(e.detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      )}
      {props.environmentType?.value == 'dwserverless' && (
        <Container header={<Header variant={'h2'}>Data Warehouse environment details</Header>}>
          <SpaceBetween size={'s'} direction={'vertical'}>
            <FormField
              label={'Workgroup name'}
              description={'Provide a Redshift workgroup name in the target account.'}
            >
              <Input value={props.workgroupName} onChange={(e) => props.setWorkgroupName(e.detail.value)} />
            </FormField>
            <FormField label={'Database name'} description={'The name of the database.'}>
              <Input
                value={props.databaseName}
                placeholder={'database_name'}
                onChange={(e) => props.setDatabaseName(e.detail.value)}
              />
            </FormField>
            <FormField
              label={'Credentials secret ARN'}
              description={
                'The ARN of the secret in Secrets Manager in the target account containing the Redshift credentials. For more info, click here.'
              }
            >
              <Input
                value={props.secretArn}
                placeholder={'arn:aws:secretsmanager:us-east-1:123412341234:secret:SecretName'}
                onChange={(e) => props.setSecretArn(e.detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      )}
    </SpaceBetween>
  );
};
