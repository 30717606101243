import * as React from 'react';
import { MetricTableView } from 'src/components/metricstore/metriccollection/metricTableView';
import { ViewType } from 'src/components/metricstore/helpers';

export interface weeklyViewProps {
  setContentType: any;
  match: any;
}

export const WeeklyView = (props: weeklyViewProps) => {
  return <MetricTableView props={props} viewType={ViewType.WEEKLY} columnCount={10} />;
};
