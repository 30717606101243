import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  Container,
  SpaceBetween,
  Header,
  Select,
  FormField,
  SelectProps,
  Input,
  Alert,
  Box,
  Button,
  Table,
  Modal,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { getUser } from 'src/api/auth';
import { getUserInfo, getWorkspacesForGroups } from 'src/api/permissions';
import {
  DataZoneMembershipDesignationInfo,
  DataZoneMembershipDesignationInfoList,
  DZEntity,
  Workspace,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { LINK, SLACK } from 'src/components/dataadvisory/constants';

export interface ConfigureProjectMembershipContentProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
  errorMessage?: string;
  //existingProjectEntity?: DZEntity;
  membershipList?: DataZoneMembershipDesignationInfoList;
  setMembershipList: any;
}

export const designationNameMapping = {
  PROJECT_CATALOG_VIEWER: 'Data viewer',
  PROJECT_CATALOG_STEWARD: 'Data steward',
};

export const ConfigureProjectMembershipContent = (props: ConfigureProjectMembershipContentProps) => {
  const ENVIRONMENT_TYPE_OPTIONS = [
    { value: 'dwcluster', label: 'Data Warehouse (Redshift)' },
    { value: 'dwserverless', label: 'Data Warehouse (Redshift Serverless)' },
  ];

  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState<string>(undefined);
  // TODO: options should not be state
  const [modalVisible, setModalVisible] = useState(false);
  const [currentEditValue, setCurrentEditValue] = useState(undefined);
  const [newItemDesignation, setNewItemDesignation] = useState<SelectProps.Option>(undefined);
  const [newItemPosixGroups, setNewItemPosixGroups] = useState<string>(undefined);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    //const abc : DZEntity;
    //abc.projectMetadata.membershipList[0].
  };

  const memberTypeNameMapping = {
    SSO_GROUP: 'SSO group',
  };

  const designationOptions = [
    {
      value: 'PROJECT_CATALOG_STEWARD',
      label: 'Data steward',
    },
    {
      value: 'PROJECT_CATALOG_VIEWER',
      label: 'Data viewer',
    },
  ];

  const addItem = () => {
    setModalVisible(true);
  };

  const resetAndCloseModal = () => {
    setModalVisible(false);
    setNewItemDesignation(undefined);
    setNewItemPosixGroups(undefined);
  };

  const submitModal = () => {
    let item = {
      designation: newItemDesignation.value,
      posixGroups: commaSeparatedListToArray(newItemPosixGroups),
      memberType: 'SSO_GROUP',
    } as DataZoneMembershipDesignationInfo;
    let newItems = [];
    newItems.push(...props.membershipList);
    newItems.push(item);
    props.setMembershipList(newItems);
    resetAndCloseModal();
  };

  const commaSeparatedListToArray = (commaSeparatedString: string) => {
    return commaSeparatedString.trim().replaceAll(/\s/g, '').split(',');
  };

  const arrayToCommaSeparatedString = (arr: string[]) => {
    return arr.join(', ');
  };

  const arrayToUnorderedList = (arr: string[]) => {
    return (
      <ul>
        {arr.map((posixGroup) => (
          <li>{posixGroup}</li>
        ))}
      </ul>
    );
  };

  if (redirect) {
    if (redirect) return <Redirect push to={redirect} />;
  }

  return (
    <>
      <Modal
        visible={modalVisible}
        header={'Add membership configuration'}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={resetAndCloseModal}>
                Cancel
              </Button>
              <Button variant='primary' onClick={submitModal}>
                Add
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <FormField
          label={'Member designation'}
          description={
            'Select the level of permissions these users will have. Viewer designation is read-only permissions, and Steward has data publishing permissions.'
          }
        >
          <Select
            selectedOption={newItemDesignation}
            onChange={(event) => {
              setNewItemDesignation(event.detail.selectedOption);
            }}
            options={designationOptions}
          />
        </FormField>
        <FormField
          label={'POSIX groups'}
          description={
            'Comma-separated list of POSIX groups to assign these permissions to. POSIX groups must begin with prefix "omni-dz-" or "omnidz-".'
          }
        >
          <Input
            value={newItemPosixGroups}
            onChange={(event) => {
              setNewItemPosixGroups(event.detail.value);
            }}
            placeholder={'omni-dz-group-a, omnidz-group-b'}
          />
        </FormField>
      </Modal>
      <Table
        items={props.membershipList}
        ariaLabels={{
          activateEditLabel: (column, item) => `Edit ${item.name} ${column.header}`,
          cancelEditLabel: (column) => `Cancel editing ${column.header}`,
          submitEditLabel: (column) => `Submit editing ${column.header}`,
        }}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign={'center'} color={'inherit'}>
            <SpaceBetween size={'m'}>
              <b>No membership configuration</b>
              <p>Add membership above.</p>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            counter={'(' + props.membershipList.length + ')'}
            actions={
              <Button variant={'normal'} iconName={'add-plus'} onClick={() => addItem()}>
                Add
              </Button>
            }
          >
            Project membership
          </Header>
        }
        columnDefinitions={[
          {
            id: 'designation',
            header: 'Designation',
            cell: (item: DataZoneMembershipDesignationInfo) => designationNameMapping[item.designation],
            minWidth: 35,
            editConfig: {
              ariaLabel: 'Contact type',
              editIconAriaLabel: 'editable',
              errorIconAriaLabel: 'Type error',
              editingCell: (item, { currentValue, setValue }) => {
                const value = currentValue ?? item.designation;
                return (
                  <Select
                    autoFocus={true}
                    expandToViewport={true}
                    selectedOption={designationOptions.find((option) => option.value === value) ?? null}
                    onChange={(event) => {
                      setValue(event.detail.selectedOption.value ?? item.designation);
                      setCurrentEditValue(event.detail.selectedOption.value ?? item.designation);
                    }}
                    options={designationOptions}
                  />
                );
              },
            },
          },
          {
            id: 'posix',
            header: 'POSIX group',
            cell: (item: DataZoneMembershipDesignationInfo) => arrayToUnorderedList(item.posixGroups),
            minWidth: 125,
            editConfig: {
              ariaLabel: 'POSIX group',
              editIconAriaLabel: 'editable',
              errorIconAriaLabel: 'POSIX group error',
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.value}
                    onChange={(event) => {
                      const value = event.detail.value ?? item.value;
                      setValue(value);
                      setCurrentEditValue(value);
                    }}
                    placeholder={'omni-dz-groupname'}
                  />
                );
              },
            },
          },
          {
            id: 'type',
            header: 'Member type',
            cell: (item: DataZoneMembershipDesignationInfo) => memberTypeNameMapping[item.memberType],
            minWidth: 175,
          },
        ]}
        submitEdit={(item, column) => {
          const newItems = props.membershipList.map((itm) => {
            if (itm == item) {
              if (column.header == 'Designation') {
                return {
                  designation: currentEditValue,
                  posixGroups: itm.posixGroups,
                  memberType: itm.memberType,
                } as DataZoneMembershipDesignationInfo;
              } else if (column.header == 'POSIX group') {
                return {
                  designation: itm.designation,
                  posixGroups: commaSeparatedListToArray(currentEditValue as string),
                  memberType: itm.memberType,
                } as DataZoneMembershipDesignationInfo;
              }
            }
            return itm;
          });
          props.setMembershipList(newItems);
          setCurrentEditValue(undefined);
        }}
      />
    </>
  );
};
