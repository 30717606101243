import * as React from 'react';
import { useState, useEffect } from 'react';

import { defaultPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Header,
  SpaceBetween,
  Box,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { DZEntity } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { createWorkflowStatusLink } from 'src/routes';
import { designationNameMapping } from 'src/components/datazone/onboard/configureProjectMembershipContent';

export interface DataZoneProjectsTableProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  nextToken?: string;
  loadMoreResults?: any;
  loading: boolean;
  projectEntities: DZEntity[];
}

export const DataZoneProjectsTable = (props: DataZoneProjectsTableProps) => {
  const [tableMessage] = useState('No projects');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  function populateSSOGroupsCell(item: awsdatalakegladstonelambda.DZEntity) {
    let displayMap =
      item.projectMetadata && item.projectMetadata.membershipList
        ? item.projectMetadata.membershipList.reduce((map, item) => {
            const { designation } = item;
            if (!map.has(designation)) {
              map.set(designation, []);
            }
            map.get(designation).push(...item.posixGroups);
            return map;
          }, new Map())
        : new Map();
    return (
      <div>
        {Array.from(displayMap).map(([key, value]) => (
          <div key={key}>
            <b>{designationNameMapping[key] ? designationNameMapping[key] : key}</b>: &emsp; {value.join(', ')}
          </div>
        ))}
      </div>
    );
  }

  const columnDefinitions: TableProps.ColumnDefinition<DZEntity>[] = [
    {
      id: 'projectName',
      header: 'Project name',
      cell: (item) => item.groupId,
      width: 250,
      sortingField: 'projectName',
    },
    {
      id: 'workflowId',
      header: 'Workflow ID',
      cell: (item) =>
        item.workflowConfig && item.workflowConfig.onboardWorkflowId ? (
          <Link to={createWorkflowStatusLink(item.workflowConfig.onboardWorkflowId)}>
            {' '}
            {item.workflowConfig.onboardWorkflowId}{' '}
          </Link>
        ) : (
          ''
        ),
      width: 330,
      sortingField: 'workflowId',
    },
    {
      id: 'sso',
      header: <>Role: &emsp; SSO groups</>,
      cell: (item) => populateSSOGroupsCell(item),
      width: 300,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    props.projectEntities,
    {
      filtering: {
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No projects to display.</p>
          </div>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading projects...'
        loading={props.loading}
        columnDefinitions={columnDefinitions}
        items={items}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='m'>
              <b>No projects</b>
              <p>Projects will be created when you onboard an environment.</p>
            </SpaceBetween>
          </Box>
        }
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={<Header counter={'(' + props.projectEntities.length + ')'}>DataZone projects</Header>}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
