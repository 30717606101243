import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  BreadcrumbsBaselineDataAccessPermission,
  BreadcrumbsBootstrapActionDetails,
  BreadcrumbsBootstrapActionFormNew,
  BreadcrumbsBootstrapActionFormUpdate,
  BreadcrumbsBrowseCatalogs,
  BreadcrumbsBrowseDatabases,
  BreadcrumbsBrowseDatasets,
  BreadcrumbsBrowseWorkspaces,
  BreadcrumbsBusinessGlossaries,
  BreadcrumbsCatalogDetails,
  BreadcrumbsConnectionDetails,
  BreadcrumbsConsumedPermissions,
  BreadcrumbsContractDetail,
  BreadcrumbsCreateBackfill,
  BreadcrumbsCreateBusinessGlossary,
  BreadcrumbsCreateConnection,
  BreadcrumbsCreateDependency,
  BreadcrumbsCreateFgaPolicy,
  BreadcrumbsCreateLakeFormationPermission,
  BreadcrumbsCreateMetadataForm,
  BreadcrumbsCreateNewGroup,
  BreadcrumbsCreateNotifications,
  BreadcrumbsCreateNotificationTemplate,
  BreadcrumbsCreateSubscription,
  BreadcrumbsCreateTag,
  BreadcrumbsCreateTemplate,
  BreadcrumbsCreateWorkspace,
  BreadcrumbsDatabaseDetails,
  BreadcrumbsDatasetDetails,
  BreadcrumbsDependencyDetails,
  BreadcrumbsEditBusinessGlossary,
  BreadcrumbsEditFgaPolicy,
  BreadcrumbsEditMetadataForm,
  BreadcrumbsEditTag,
  BreadcrumbsEditTemplate,
  BreadcrumbsEventSubscriptionDetails,
  BreadcrumbsEventSubscriptions,
  BreadcrumbsFgaPolicies,
  BreadcrumbsMetadataForms,
  BreadcrumbsMonitoringDataset,
  BreadcrumbsMyBaselining,
  BreadcrumbsMyBootstrapActions,
  BreadcrumbsMyConnections,
  BreadcrumbsMyDatasets,
  BreadcrumbsMyDatasetsDetails,
  BreadcrumbsMyDependencies,
  BreadcrumbsMyGroup,
  BreadcrumbsMyGroups,
  BreadcrumbsMyNotifications,
  BreadcrumbsMyRequests,
  BreadcrumbsMyResourceGroups,
  BreadcrumbsMySubscriptions,
  BreadcrumbsNotificationBatchDetail,
  BreadcrumbsNotificationConfiguration,
  BreadcrumbsNotificationDetail,
  BreadcrumbsNotificationTemplateDetail,
  BreadcrumbsNotificationTemplates,
  BreadcrumbsPermissionDetail,
  BreadcrumbsPermissionRequestDetails,
  BreadcrumbsPostDedupe,
  BreadcrumbsPublish,
  BreadcrumbsPublishedTables,
  BreadcrumbsPublisherAccounts,
  BreadcrumbsPublisherRegisterAccount,
  BreadcrumbsPublishRegisterTable,
  BreadcrumbsPublishTableDetails,
  BreadcrumbsRegisterContract,
  BreadcrumbsResourceDetails,
  BreadcrumbsResourceGroupDetails,
  BreadcrumbsResourceGroupFormNew,
  BreadcrumbsResourceGroupFormUpdate,
  BreadcrumbsSearchData,
  BreadcrumbsSubscriptionDetails,
  BreadcrumbsTags,
  BreadcrumbsTemplateDetails,
  BreadcrumbsTemplateNew,
  BreadcrumbsTemplates,
  BreadcrumbsTemplateUpdate,
  BreadcrumbsTemplateView,
  BreadcrumbsUpdateContract,
  BreadcrumbsUpdateNotificationTemplate,
  BreadcrumbsUpdateWorkspace,
  BreadcrumbsViewBusinessGlossary,
  BreadcrumbsViewFgaPolicy,
  BreadcrumbsListAdvisories,
  BreadcrumbsCreateAdvisory,
  BreadcrumbsCreateAdvisoryFromTemplate,
  BreadcrumbsEditAdvisory,
  BreadcrumbsSendAdvisoryNotification,
  BreadcrumbsListAdvisoryTemplates,
  BreadcrumbsEditAdvisoryTemplate,
  BreadcrumbsAdvisoryTemplateDetail,
  BreadcrumbsAdvisoryDetail,
  BreadcrumbsCreateAdvisoryFromAdvisory,
  BreadcrumbsViewMetadataForm,
  BreadcrumbsWorkspaceDetail,
  BreadcrumbsWorkspaceMyRequests,
  BreadcrumbsWorkspaceMyRequestsDetail,
  BreadCrumbsWorkspaceTagAccess,
  BreadcrumbsWSBrowseCatalogs,
  BreadcrumbsWSBrowseDatabases,
  BreadcrumbsWSBrowseDatasets,
  BreadcrumbsWSDatabaseDetails,
  BreadcrumbsWSDatasetDetails,
  BreadcrumbsWSDetails,
  BreadcrumbsWSGlueRegister,
  BreadcrumbsWSRedshiftQuery,
  BreadcrumbsWSRedshiftRegister,
  BreadcrumbsWSResourceLinks,
  BreadcrumbsWSSchemaDetails,
  BreadcrumbsWSTagDetails,
  BreadcrumbsWSTagRegister,
  BreadcrumbsBaselineMembership,
  BreadcrumbsViewMetricCollectionsCatalog,
  BreadcrumbsMetricCollectionDefinition,
  BreadcrumbsViewMetricsCatalog,
  BreadcrumbsMetricsCatalogDefinition,
  BreadcrumbsViewReportingTablesCatalog,
  BreadcrumbsReportingTableDefinition,
  BreadcrumbsMetricCollectionMonthlyView,
  BreadcrumbsMetricCollectionYearlyView,
  BreadcrumbsMetricCollectionQuarterlyView,
  BreadcrumbsMetricCollectionWeeklyView,
  BreadcrumbsDataZoneOnboard,
  BreadcrumbsDataZoneManage,
} from '../commons/breadcrumbs';
import { Page } from 'src/routes/Paths';

export const Breadcrumbs = (props) => (
  <Switch>
    <Route
      path={Page.METRICS_CATALOG}
      exact
      component={(route_props) => <BreadcrumbsViewMetricsCatalog {...route_props} {...props} />}
    />
    <Route
      path={Page.METRIC_DEFINITION}
      exact
      component={(route_props) => <BreadcrumbsMetricsCatalogDefinition {...route_props} {...props} />}
    />
    <Route
      path={Page.METRIC_COLLECTIONS_CATALOG}
      exact
      component={(route_props) => <BreadcrumbsViewMetricCollectionsCatalog {...route_props} {...props} />}
    />
    <Route
      path={Page.METRIC_COLLECTIONS_DEFINITION}
      exact
      component={(route_props) => <BreadcrumbsMetricCollectionDefinition {...route_props} {...props} />}
    />
    <Route
      path={Page.METRIC_COLLECTION_WEEKLY_VIEW}
      exact
      component={(route_props) => <BreadcrumbsMetricCollectionWeeklyView {...route_props} {...props} />}
    />
    <Route
      path={Page.METRIC_COLLECTION_MONTHLY_VIEW}
      exact
      component={(route_props) => <BreadcrumbsMetricCollectionMonthlyView {...route_props} {...props} />}
    />
    <Route
      path={Page.METRIC_COLLECTION_QUARTERLY_VIEW}
      exact
      component={(route_props) => <BreadcrumbsMetricCollectionQuarterlyView {...route_props} {...props} />}
    />
    <Route
      path={Page.METRIC_COLLECTION_YEARLY_VIEW}
      exact
      component={(route_props) => <BreadcrumbsMetricCollectionYearlyView {...route_props} {...props} />}
    />
    <Route
      path={Page.REPORTING_TABLE_CATALOG}
      exact
      component={(route_props) => <BreadcrumbsViewReportingTablesCatalog {...route_props} {...props} />}
    />
    <Route
      path={Page.REPORTING_TABLE_DEFINITION}
      exact
      component={(route_props) => <BreadcrumbsReportingTableDefinition {...route_props} {...props} />}
    />
    <Route
      path={Page.BROWSE_CATALOGS}
      exact
      render={(route_props) => <BreadcrumbsBrowseCatalogs {...route_props} {...props} />}
    />
    <Route
      path={[
        Page.CATALOG_DETAILS,
        Page.REDSHIFT_CATALOG_DETAILS,
        Page.REDSHIFT_SERVERLESS_CATALOG_DETAILS,
        Page.DEPRECATED_CATALOG_DETAIL,
      ]}
      exact
      render={(route_props) => <BreadcrumbsCatalogDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.BROWSE_DATABASES}
      exact
      render={(route_props) => <BreadcrumbsBrowseDatabases {...route_props} {...props} />}
    />
    <Route
      path={Page.DATABASE_DETAILS}
      exact
      render={(route_props) => <BreadcrumbsDatabaseDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.BROWSE_DATASETS}
      exact
      render={(route_props) => <BreadcrumbsBrowseDatasets {...route_props} {...props} />}
    />
    <Route
      path={Page.DATASET_DETAILS}
      exact
      render={(route_props) => <BreadcrumbsDatasetDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.LF_PERMISSION_DETAIL}
      exact
      render={(route_props) => <BreadcrumbsPermissionDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.CONNECTIONS}
      exact
      render={(route_props) => <BreadcrumbsMyConnections {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_CONNECTION}
      exact
      render={(route_props) => <BreadcrumbsCreateConnection {...route_props} {...props} />}
    />
    <Route
      path={Page.CONNECTION_DETAILS}
      exact
      render={(route_props) => <BreadcrumbsConnectionDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.SUBSCRIPTIONS}
      exact
      render={(route_props) => <BreadcrumbsMySubscriptions {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_SUBSCRIPTION}
      exact
      render={(route_props) => <BreadcrumbsCreateSubscription {...route_props} {...props} />}
    />
    <Route
      path={Page.SUBSCRIPTION_DETAILS}
      exact
      render={(route_props) => <BreadcrumbsSubscriptionDetails {...route_props} {...props} />}
    />

    <Route path={Page.PUBLISH} exact render={(route_props) => <BreadcrumbsPublish {...route_props} {...props} />} />

    <Route
      path={Page.PUBLISHER_ACCOUNTS}
      exact
      render={(route_props) => <BreadcrumbsPublisherAccounts {...route_props} {...props} />}
    />
    <Route
      path={Page.PUBLISHER_REGISTER_ACCOUNT}
      exact
      render={(route_props) => <BreadcrumbsPublisherRegisterAccount {...route_props} {...props} />}
    />

    <Route
      path={Page.PUBLISHER_TABLES}
      exact
      render={(route_props) => <BreadcrumbsPublishedTables {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_PUBLISHER_TABLE}
      exact
      render={(route_props) => <BreadcrumbsPublishRegisterTable {...route_props} {...props} />}
    />
    <Route
      path={Page.PUBLISHER_CREATE_BACKFILL}
      exact
      render={(route_props) => <BreadcrumbsCreateBackfill {...route_props} {...props} />}
    />
    <Route
      path={Page.PUBLISHER_POST_DEDUPE}
      exact
      render={(route_props) => <BreadcrumbsPostDedupe {...route_props} {...props} />}
    />
    <Route
      path={Page.PUBLISHER_TABLE_DETAILS}
      exact
      render={(route_props) => <BreadcrumbsPublishTableDetails {...route_props} {...props} />}
    />

    <Route path={Page.REQUESTS} exact component={BreadcrumbsMyRequests} />
    <Route path={Page.BASELINING} exact component={BreadcrumbsMyBaselining} />
    <Route path={Page.BASELINE_DATA_ACCESS_PERMISSION} exact component={BreadcrumbsBaselineDataAccessPermission} />

    <Route path={Page.BASELINE_OWNER} exact component={BreadcrumbsBaselineMembership} />

    <Route path={Page.LIST_ADVISORIES} exact component={BreadcrumbsListAdvisories} />
    <Route path={Page.CREATE_ADVISORY} exact component={BreadcrumbsCreateAdvisory} />
    <Route path={Page.CREATE_ADVISORY_FROM_TEMPLATE} exact component={BreadcrumbsCreateAdvisoryFromTemplate} />
    <Route path={Page.CREATE_ADVISORY_FROM_ADVISORY} exact component={BreadcrumbsCreateAdvisoryFromAdvisory} />
    <Route path={Page.UPDATE_ADVISORY} exact component={BreadcrumbsEditAdvisory} />
    <Route path={Page.ADVISORY_SEND_NOTIFICATION} exact component={BreadcrumbsSendAdvisoryNotification} />
    <Route path={Page.LIST_ADVISORY_TEMPLATES} exact component={BreadcrumbsListAdvisoryTemplates} />
    <Route path={Page.UPDATE_ADVISORY_TEMPLATE} exact component={BreadcrumbsEditAdvisoryTemplate} />
    <Route path={Page.ADVISORY_TEMPLATE_DETAIL} exact component={BreadcrumbsAdvisoryTemplateDetail} />
    <Route path={Page.ADVISORY_DETAIL} exact component={BreadcrumbsAdvisoryDetail} />

    <Route
      path={Page.CREATE_LAKE_FORMATION_PERMISSIONS}
      exact
      render={(route_props) => <BreadcrumbsCreateLakeFormationPermission {...route_props} {...props} />}
    />

    <Route
      path={Page.REDSHIFT_PERMISSION_DETAIL}
      exact
      render={(route_props) => <BreadcrumbsPermissionDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.PUBLISHED_PERMISSION_DETAIL}
      exact
      render={(route_props) => <BreadcrumbsPermissionDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.REQUEST_DETAILS}
      exact
      render={(route_props) => <BreadcrumbsPermissionRequestDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.MY_DATASETS}
      exact
      render={(route_props) => <BreadcrumbsMyDatasets {...route_props} {...props} />}
    />
    <Route
      path={Page.DATASETSHARE_DETAILS}
      exact
      render={(route_props) => <BreadcrumbsMyDatasetsDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.DEPENDENCIES}
      exact
      render={(route_props) => <BreadcrumbsMyDependencies {...route_props} {...props} />}
    />

    <Route
      path={Page.CREATE_DEPENDENCY}
      exact
      render={(route_props) => <BreadcrumbsCreateDependency {...route_props} {...props} />}
    />
    <Route
      path={Page.DEPENDENCY_DETAILS}
      exact
      render={(route_props) => <BreadcrumbsDependencyDetails {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_NEW_GROUP}
      exact
      render={(route_props) => <BreadcrumbsCreateNewGroup {...route_props} {...props} />}
    />
    <Route path={Page.GROUP} exact render={(route_props) => <BreadcrumbsMyGroup {...route_props} {...props} />} />
    <Route path={Page.GROUPS} exact render={(route_props) => <BreadcrumbsMyGroups {...route_props} {...props} />} />

    <Route
      path={Page.WORKSPACEACCESS}
      exact
      render={(route_props) => <BreadCrumbsWorkspaceTagAccess {...route_props} {...props} />}
    />

    <Route path={Page.TAGS} exact render={(route_props) => <BreadcrumbsTags {...route_props} {...props} />} />

    <Route
      path={Page.CREATE_TAGS_TEMPLATE}
      exact
      render={(route_props) => <BreadcrumbsCreateTag {...route_props} {...props} />}
    />

    <Route
      path={Page.EDIT_TAGS_TEMPLATE}
      exact
      render={(route_props) => <BreadcrumbsEditTag {...route_props} {...props} />}
    />

    <Route path={Page.NOTIFICATIONS} exact component={BreadcrumbsMyNotifications} />
    <Route path={Page.CREATE_NOTIFICATION} exact component={BreadcrumbsCreateNotifications} />
    <Route path={Page.NOTIFICATION_DETAILS} exact component={BreadcrumbsNotificationDetail} />
    <Route path={Page.NOTIFICATION_BATCH_DETAILS} exact component={BreadcrumbsNotificationBatchDetail} />

    <Route path={Page.NOTIFICATION_TEMPLATES} exact component={BreadcrumbsNotificationTemplates} />
    <Route path={Page.CREATE_NOTIFICATION_TEMPLATE} exact component={BreadcrumbsCreateNotificationTemplate} />
    <Route path={Page.UPDATE_NOTIFICATION_TEMPLATE} exact component={BreadcrumbsUpdateNotificationTemplate} />
    <Route path={Page.NOTIFICATION_TEMPLATE_DETAILS} exact component={BreadcrumbsNotificationTemplateDetail} />
    <Route path={Page.NOTIFICATION_CONFIGURATION} exact component={BreadcrumbsNotificationConfiguration} />

    <Route
      path={Page.RESOURCEGROUPS}
      exact
      component={(route_props) => <BreadcrumbsMyResourceGroups {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_RESOURCEGROUP}
      exact
      component={(route_props) => <BreadcrumbsResourceGroupFormNew {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_RESOURCEGROUP}
      exact
      component={(route_props) => <BreadcrumbsResourceGroupFormUpdate {...route_props} {...props} />}
    />
    <Route
      path={Page.RESOURCEGROUP_DETAILS}
      exact
      component={(route_props) => <BreadcrumbsResourceGroupDetails {...route_props} {...props} />}
    />
    <Route
      path={Page.RESOURCE_DETAILS}
      exact
      component={(route_props) => <BreadcrumbsResourceDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.EVENT_SUBSCRIPTIONS}
      exact
      component={(route_props) => <BreadcrumbsEventSubscriptions {...route_props} {...props} />}
    />
    <Route
      path={Page.EVENT_SUBSCRIPTIONS_DETAILS}
      exact
      component={(route_props) => <BreadcrumbsEventSubscriptionDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.BOOTSTRAPACTIONS}
      exact
      component={(route_props) => <BreadcrumbsMyBootstrapActions {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_BOOTSTRAPACTION}
      exact
      component={(route_props) => <BreadcrumbsBootstrapActionFormNew {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_BOOTSTRAPACTION}
      exact
      component={(route_props) => <BreadcrumbsBootstrapActionFormUpdate {...route_props} {...props} />}
    />
    <Route
      path={Page.BOOTSTRAPACTION_DETAILS}
      exact
      component={(route_props) => <BreadcrumbsBootstrapActionDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.CREATE_RG_TEMPLATE}
      exact
      component={(route_props) => <BreadcrumbsTemplateNew {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_RG_TEMPLATE}
      exact
      component={(route_props) => <BreadcrumbsTemplateUpdate {...route_props} {...props} />}
    />
    <Route
      path={Page.RG_TEMPLATE_DETAILS}
      exact
      component={(route_props) => <BreadcrumbsTemplateDetails {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_WORKSPACE}
      exact
      component={(route_props) => <BreadcrumbsCreateWorkspace {...route_props} {...props} />}
    />
    <Route
      path={Page.BROWSE_WORKSPACES}
      exact
      component={(route_props) => <BreadcrumbsBrowseWorkspaces {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_MY_REQUESTS}
      exact
      component={(route_props) => <BreadcrumbsWorkspaceMyRequests {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_MY_REQUEST_DETAILS}
      exact
      component={(route_props) => <BreadcrumbsWorkspaceMyRequestsDetail {...route_props} {...props} />}
    />
    <Route
      path={Page.CURRENT_WORKSPACE_DETAIL}
      exact
      component={(route_props) => <BreadcrumbsWorkspaceDetail {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_WORKSPACE}
      exact
      component={(route_props) => <BreadcrumbsUpdateWorkspace {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACE_ALL_PERMISSIONS}
      exact
      component={(route_props) => <BreadcrumbsConsumedPermissions {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACES_BROWSE_DATASETS}
      exact
      component={(route_props) => <BreadcrumbsWSBrowseDatasets {...route_props} {...props} />}
    />

    <Route
      path={Page.SEARCH}
      exact
      component={(route_props) => <BreadcrumbsSearchData {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACES_BROWSE_DATABASES}
      exact
      component={(route_props) => <BreadcrumbsWSBrowseDatabases {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACES_BROWSE_CATALOGS}
      exact
      component={(route_props) => <BreadcrumbsWSBrowseCatalogs {...route_props} {...props} />}
    />

    <Route
      path={[Page.DATABASE_DETAILS, Page.REDSHIFT_DATABASE_DETAILS, Page.REDSHIFT_SERVERLESS_DATABASE_DETAILS]}
      exact
      component={(route_props) => <BreadcrumbsWSDatabaseDetails {...route_props} {...props} />}
    />

    <Route
      path={[Page.SCHEMA_DETAILS, Page.SERVERLESS_SCHEMA_DETAILS, Page.DEPRECATED_SCHEMA_DETAIL]}
      exact
      component={(route_props) => <BreadcrumbsWSSchemaDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.DATASET_DETAILS}
      exact
      component={(route_props) => <BreadcrumbsWSDatasetDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACE_GLUE_REGISTER}
      exact
      component={(route_props) => <BreadcrumbsWSGlueRegister {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACE_REDSHIFT_REGISTER}
      exact
      component={(route_props) => <BreadcrumbsWSRedshiftRegister {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACE_TAG_REGISTER}
      exact
      component={(route_props) => <BreadcrumbsWSTagRegister {...route_props} {...props} />}
    />
    <Route
      path={Page.TEMPLATES}
      exact
      component={(route_props) => <BreadcrumbsTemplates {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_TEMPLATE}
      exact
      component={(route_props) => <BreadcrumbsCreateTemplate {...route_props} {...props} />}
    />
    <Route
      path={Page.TEMPLATE_DETAILS}
      exact
      component={(route_props) => <BreadcrumbsTemplateView {...route_props} {...props} />}
    />
    <Route
      path={Page.EDIT_TEMPLATE}
      exact
      component={(route_props) => <BreadcrumbsEditTemplate {...route_props} {...props} />}
    />

    <Route
      path={Page.FINE_GRAIN_ACCESS_POLICIES}
      exact
      component={(route_props) => <BreadcrumbsFgaPolicies {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_FINE_GRAIN_ACCESS_POLICY}
      exact
      component={(route_props) => <BreadcrumbsCreateFgaPolicy {...route_props} {...props} />}
    />
    <Route
      path={Page.EDIT_FINE_GRAIN_ACCESS_POLICY}
      exact
      component={(route_props) => <BreadcrumbsEditFgaPolicy {...route_props} {...props} />}
    />
    <Route
      path={Page.VIEW_FINE_GRAIN_ACCESS_POLICY}
      exact
      component={(route_props) => <BreadcrumbsViewFgaPolicy {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACE_RESOURCE_LINKS}
      exact
      component={(route_props) => <BreadcrumbsWSResourceLinks {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACE_REDSHIFT_QUERY}
      exact
      component={(route_props) => <BreadcrumbsWSRedshiftQuery {...route_props} {...props} />}
    />

    <Route
      path={Page.INGESTION.REGISTER_CONTRACT}
      exact
      component={(route_props) => <BreadcrumbsRegisterContract {...route_props} {...props} />}
    />

    <Route
      path={Page.INGESTION.UPDATE_CONTRACT_V2}
      component={(route_props) => <BreadcrumbsUpdateContract {...route_props} {...props} />}
    />

    <Route
      path={Page.INGESTION.UPDATE_CONTRACT}
      component={(route_props) => <BreadcrumbsUpdateContract {...route_props} {...props} />}
    />

    <Route
      path={Page.INGESTION.DETAIL}
      component={(route_props) => <BreadcrumbsContractDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.INGESTION.MONITORING}
      component={(route_props) => <BreadcrumbsMonitoringDataset {...route_props} {...props} />}
    />

    <Route
      path={Page.INGESTION.MONITORING_V2}
      component={(route_props) => <BreadcrumbsMonitoringDataset {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACE_DETAIL}
      exact
      render={(route_props) => <BreadcrumbsWSDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.TAG_DETAIL}
      exact
      render={(route_props) => <BreadcrumbsWSTagDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.METADATA_FORMS}
      exact
      component={(route_props) => <BreadcrumbsMetadataForms {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_METADATA_FORM}
      exact
      component={(route_props) => <BreadcrumbsCreateMetadataForm {...route_props} {...props} />}
    />
    <Route
      path={Page.EDIT_METADATA_FORM}
      exact
      component={(route_props) => <BreadcrumbsEditMetadataForm {...route_props} {...props} />}
    />
    <Route
      path={Page.VIEW_METADATA_FORM}
      exact
      component={(route_props) => <BreadcrumbsViewMetadataForm {...route_props} {...props} />}
    />

    <Route
      path={Page.BUSINESS_GLOSSARIES}
      exact
      component={(route_props) => <BreadcrumbsBusinessGlossaries {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_BUSINESS_GLOSSARY}
      exact
      component={(route_props) => <BreadcrumbsCreateBusinessGlossary {...route_props} {...props} />}
    />
    <Route
      path={Page.EDIT_BUSINESS_GLOSSARY}
      exact
      component={(route_props) => <BreadcrumbsEditBusinessGlossary {...route_props} {...props} />}
    />
    <Route
      path={Page.VIEW_BUSINESS_GLOSSARY}
      exact
      component={(route_props) => <BreadcrumbsViewBusinessGlossary {...route_props} {...props} />}
    />
    <Route path={Page.DATAZONE_MANAGE} exact component={BreadcrumbsDataZoneManage} />
    <Route path={Page.DATAZONE_ONBOARD} exact component={BreadcrumbsDataZoneOnboard} />
  </Switch>
);

export default Breadcrumbs;
