import awsdatalakegladstonelambda, {
  CreateLakeFormationRequest,
  CreateLakeFormationResult,
  EditDataLakeRoleRequest,
  EditDataLakeRoleResult,
  GetRequestsRequest,
  GetRequestsResult,
  GetRequestRequest,
  GetRequestResult,
  CreateGroupRequest,
  CreateGroupResult,
  CreateUsersRequest,
  CreateUsersResult,
  EditUsersRequest,
  EditUsersResult,
  EditGroupRequest,
  EditGroupResult,
  GetGroupRequest,
  GetGroupResult,
  GetGroupMembersRequest,
  GetGroupMembersResult,
  GetUserRequest,
  GetUserResult,
  ListRolesRequest,
  ListRolesResult,
  ApproveRequest,
  ApproveResult,
  DenyRequest,
  DenyResult,
  CancelRequest,
  CancelResult,
  ListDataLakeRolePropertyRequest,
  ListDataLakeRolePropertyResult,
  ListPermissionsRequest,
  ListPermissionsResult,
  BatchApproveRequest,
  BatchApproveResult,
  BatchDenyRequest,
  BatchDenyResult,
  GetGroupsConsumingDatasetRequest,
  GetGroupsConsumingDatasetResult,
  GetGroupsRequest,
  GetGroupsResult,
  IsMemberOfGroupingMechanismIdRequest,
  IsMemberOfGroupingMechanismIdResponse,
  GetGroupBaselineInfoRequest,
  GetGroupBaselineInfoResult,
  GetTagsRequest,
  GetTagsResult,
  GetTaggedResourcesRequest,
  GetTaggedResourcesResult,
  GetTagSharedWorkspacesRequest,
  GetTagSharedWorkspacesResult,
  CreateTagRequest,
  CreateTagResult,
  EditTagRequest,
  EditTagResult,
  CreateWorkspaceRequest,
  CreateWorkspaceResult,
  SyncWorkspaceRequest,
  SyncWorkspaceResult,
  AuditWorkspaceRequest,
  AuditWorkspaceResult,
  EditWorkspaceRequest,
  EditWorkspaceResult,
  GetWorkspaceRequest,
  GetWorkspaceResult,
  ListWorkspacesRequest,
  ListWorkspacesResult,
  GetWorkspacesByGroupIdRequest,
  GetWorkspacesByGroupIdResult,
  GetWorkspaceSupportedServicesRequest,
  GetWorkspaceSupportedServicesResult,
  AssociateTagToResourcesRequest,
  AssociateTagToResourcesResult,
  DisassociateTagFromResourcesRequest,
  DisassociateTagFromResourcesResult,
  CreateDataPermissionRequest,
  CreateDataPermissionResult,
  GetDataPermissionRequestsRequest,
  GetDataPermissionRequestsResult,
  CreateRequestForDataPermissionRequest,
  CreateRequestForDataPermissionResult,
  GetTagBasedWorkspaceAccessRequest,
  GetTagBasedWorkspaceAccessResult,
  CancelTagBasedWorkspaceAccessRequest,
  CancelTagBasedWorkspaceAccessResult,
  GetResourceTagsRequest,
  GetResourceTagsResult,
  ListDataPermissionsRequest,
  ListDataPermissionsResult,
  CreateResourceLinkForDataPermissionRequest,
  CreateResourceLinkForDataPermissionResult,
  GetWorkspaceCredentialsRequest,
  GetWorkspaceCredentialsResult,
  GetDataPermissionResult,
  GetDataLinkRequest,
  GetDataLinkResult,
  GetDataPermissionRequest,
  ListTemplatesRequest,
  ListTemplatesResult,
  CreateTemplateRequest,
  CreateTemplateResult,
  GetTemplateRequest,
  GetTemplateResult,
  AssociateTemplateToResourcesRequest,
  AssociateTemplateToResourcesResult,
  EditTemplateRequest,
  EditTemplateResult,
  DeleteTemplateRequest,
  DeleteTemplateResult,
  DissociateTemplateFromResourcesRequest,
  DissociateTemplateFromResourcesResult,
  GetTemplateForResourceRequest,
  GetTemplateForResourceResult,
  DeactivateDataPermissionRequest,
  DeactivateDataPermissionResult,
  AuditDataPermissionRequest,
  AuditDataPermissionResult,
  ValidatePrerequisitesRequest,
  RegisterRedshiftClusterRequest,
  RegisterRedshiftClusterResult,
  ValidatePrerequisitesResult,
  ImportLakeFormationTagsRequest,
  ImportLakeFormationTagsResult,
  ListRedshiftClustersRequest,
  ListRedshiftClustersResult,
  SearchRequest,
  SearchResult,
  ListFineGrainAccessPolicyRequest,
  ListFineGrainAccessPolicyResult,
  CreateFineGrainAccessPolicyRequest,
  CreateFineGrainAccessPolicyResult,
  EditFineGrainAccessPolicyRequest,
  EditFineGrainAccessPolicyResult,
  DeleteFineGrainAccessPolicyRequest,
  DeleteFineGrainAccessPolicyResult,
  GetFineGrainAccessPolicyRequest,
  GetFineGrainAccessPolicyResult,
  GetGroupBatchRequest,
  GetGroupBatchResult,
  ListDataZoneEntitiesRequest,
  ListDataZoneEntitiesResult,
  StartGladstoneWorkflowRequest,
  StartGladstoneWorkflowResult,
  GetGladstoneWorkflowResult,
  GetGladstoneWorkflowRequest,
  GetExternalGroupsRequest,
  GetExternalGroupsResult,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import basicCallWrapper from 'src/api/client_call';
import { getGladstoneConfig } from 'src/api/config';
import _ from 'lodash';

const config = getGladstoneConfig();

let gladstone = new awsdatalakegladstonelambda();
let gladstone_no_retries = new awsdatalakegladstonelambda();

export async function initGladstoneClient(credentials) {
  const gladstoneClientConfig = {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
  };
  const gladstoneClientConfigWithNoRetries: awsdatalakegladstonelambda.ClientConfiguration = {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
    maxRetries: 0,
  };
  gladstone = new awsdatalakegladstonelambda(gladstoneClientConfig);
  gladstone_no_retries = new awsdatalakegladstonelambda(gladstoneClientConfigWithNoRetries);
}

export const importLakeFormationTags = (input: ImportLakeFormationTagsRequest) => {
  return basicCallWrapper<ImportLakeFormationTagsRequest, ImportLakeFormationTagsResult>(
    gladstone,
    gladstone.importLakeFormationTags,
  )(input);
};

export const createDataPermission = (input: CreateDataPermissionRequest) => {
  return basicCallWrapper<CreateDataPermissionRequest, CreateDataPermissionResult>(
    gladstone,
    gladstone.createDataPermission,
  )(input);
};

export const createLakeFormationPermission = (input: CreateLakeFormationRequest) => {
  return basicCallWrapper<CreateLakeFormationRequest, CreateLakeFormationResult>(
    gladstone,
    gladstone.createLakeFormationPermission,
  )(input);
};

export const getRequest = (input: GetRequestRequest) => {
  return basicCallWrapper<GetRequestRequest, GetRequestResult>(gladstone, gladstone.getRequest)(input);
};

export const createResourceLinkForDataPermission = (input: CreateResourceLinkForDataPermissionRequest) => {
  return basicCallWrapper<CreateResourceLinkForDataPermissionRequest, CreateResourceLinkForDataPermissionResult>(
    gladstone,
    gladstone.createResourceLinkForDataPermission,
  )(input);
};

export const getRequestedByRequests = (input: GetRequestsRequest) => {
  return basicCallWrapper<GetRequestsRequest, GetRequestsResult>(gladstone, gladstone.getRequestedByRequests)(input);
};

export const listRedshiftClusters = (input: ListRedshiftClustersRequest) => {
  return basicCallWrapper<ListRedshiftClustersRequest, ListRedshiftClustersResult>(
    gladstone,
    gladstone.listRedshiftClusters,
  )(input);
};

export const getRequestedToRequests = (input: GetRequestsRequest) => {
  return basicCallWrapper<GetRequestsRequest, GetRequestsResult>(gladstone, gladstone.getRequestedToRequests)(input);
};

export const getRequestedByRequestsHistory = (input: GetRequestsRequest) => {
  return basicCallWrapper<GetRequestsRequest, GetRequestsResult>(
    gladstone,
    gladstone.getRequestedByRequestsHistory,
  )(input);
};

export const getRequestedToRequestsHistory = (input: GetRequestsRequest) => {
  return basicCallWrapper<GetRequestsRequest, GetRequestsResult>(
    gladstone,
    gladstone.getRequestedToRequestsHistory,
  )(input);
};

export const editDataLakeRole = (input: EditDataLakeRoleRequest) => {
  return basicCallWrapper<EditDataLakeRoleRequest, EditDataLakeRoleResult>(
    gladstone,
    gladstone.editDataLakeRole,
  )(input);
};

export const createRequestForDataPermission = (input: CreateRequestForDataPermissionRequest) => {
  return basicCallWrapper<CreateRequestForDataPermissionRequest, CreateRequestForDataPermissionResult>(
    gladstone,
    gladstone.createRequestForDataPermission,
  )(input);
};

export const listDataLakeRoleProperty = (input: ListDataLakeRolePropertyRequest) => {
  return basicCallWrapper<ListDataLakeRolePropertyRequest, ListDataLakeRolePropertyResult>(
    gladstone,
    gladstone.listDataLakeRoleProperty,
  )(input);
};

export const associateTagToResources = (input: AssociateTagToResourcesRequest) => {
  return basicCallWrapper<AssociateTagToResourcesRequest, AssociateTagToResourcesResult>(
    gladstone,
    gladstone.associateTagToResources,
  )(input);
};

export const disassociateTagFromResources = (input: DisassociateTagFromResourcesRequest) => {
  return basicCallWrapper<DisassociateTagFromResourcesRequest, DisassociateTagFromResourcesResult>(
    gladstone,
    gladstone.disassociateTagFromResources,
  )(input);
};

export const getResourceTags = (input: GetResourceTagsRequest) => {
  return basicCallWrapper<GetResourceTagsRequest, GetResourceTagsResult>(gladstone, gladstone.getResourceTags)(input);
};

export const approveRequest = (input: ApproveRequest) => {
  return basicCallWrapper<ApproveRequest, ApproveResult>(gladstone, gladstone.approve)(input);
};

export const denyRequest = (input: DenyRequest) => {
  return basicCallWrapper<DenyRequest, DenyResult>(gladstone, gladstone.deny)(input);
};

export const cancelRequest = (input: CancelRequest) => {
  return basicCallWrapper<CancelRequest, CancelResult>(gladstone, gladstone.cancel)(input);
};

export const getUserInfo = (input: GetUserRequest) => {
  return basicCallWrapper<GetUserRequest, GetUserResult>(gladstone, gladstone.getUser)(input);
};

export const getTagBasedWorkspaceAccess = (input: GetTagBasedWorkspaceAccessRequest) => {
  return basicCallWrapper<GetTagBasedWorkspaceAccessRequest, GetTagBasedWorkspaceAccessResult>(
    gladstone,
    gladstone.getTagBasedWorkspaceAccess,
  )(input);
};

export const cancelTagBasedWorkspaceAccess = (input: CancelTagBasedWorkspaceAccessRequest) => {
  const returned = basicCallWrapper<CancelTagBasedWorkspaceAccessRequest, CancelTagBasedWorkspaceAccessResult>(
    gladstone,
    gladstone.cancelTagBasedWorkspaceAccess,
  )(input);
  return returned;
};

export const getGroupInfo = (input: GetGroupRequest) => {
  return basicCallWrapper<GetGroupRequest, GetGroupResult>(gladstone, gladstone.getGroup)(input);
};

export const getGroupBatch = (input: GetGroupBatchRequest) => {
  return basicCallWrapper<GetGroupBatchRequest, GetGroupBatchResult>(gladstone, gladstone.getGroupBatch)(input);
};

export const getGroupMembers = (input: GetGroupMembersRequest) => {
  return basicCallWrapper<GetGroupMembersRequest, GetGroupMembersResult>(gladstone, gladstone.getGroupMembers)(input);
};

export const createGroup = (input: CreateGroupRequest) => {
  return basicCallWrapper<CreateGroupRequest, CreateGroupResult>(gladstone, gladstone.createGroup)(input);
};

export const createUsers = (input: CreateUsersRequest) => {
  return basicCallWrapper<CreateUsersRequest, CreateUsersResult>(gladstone, gladstone.createUsers)(input);
};

export const listPermissions = (input: ListPermissionsRequest) => {
  return basicCallWrapper<ListPermissionsRequest, ListPermissionsResult>(gladstone, gladstone.listPermissions)(input);
};

export const listDataPermissions = (input: ListDataPermissionsRequest) => {
  return basicCallWrapper<ListDataPermissionsRequest, ListDataPermissionsResult>(
    gladstone,
    gladstone.listDataPermissions,
  )(input);
};

export const deactivateDataPermission = (input: DeactivateDataPermissionRequest) => {
  return basicCallWrapper<DeactivateDataPermissionRequest, DeactivateDataPermissionResult>(
    gladstone,
    gladstone.deactivateDataPermission,
  )(input);
};

export const auditDataPermission = (input: AuditDataPermissionRequest) => {
  return basicCallWrapper<AuditDataPermissionRequest, AuditDataPermissionResult>(
    gladstone,
    gladstone.auditDataPermission,
  )(input);
};

export const batchApprove = (input: BatchApproveRequest) => {
  return basicCallWrapper<BatchApproveRequest, BatchApproveResult>(gladstone, gladstone.batchApprove)(input);
};

export const batchDeny = (input: BatchDenyRequest) => {
  return basicCallWrapper<BatchDenyRequest, BatchDenyResult>(gladstone, gladstone.batchDeny)(input);
};

export const getGroupsConsumingDataset = (input: GetGroupsConsumingDatasetRequest) => {
  return basicCallWrapper<GetGroupsConsumingDatasetRequest, GetGroupsConsumingDatasetResult>(
    gladstone,
    gladstone.getGroupsConsumingDataset,
  )(input);
};

export const getGroups = (input: GetGroupsRequest) => {
  return basicCallWrapper<GetGroupsRequest, GetGroupsResult>(gladstone, gladstone.getGroups)(input);
};

export const editUsers = (input: EditUsersRequest) => {
  return basicCallWrapper<EditUsersRequest, EditUsersResult>(gladstone, gladstone.editUsers)(input);
};

export const editGroup = (input: EditGroupRequest) => {
  return basicCallWrapper<EditGroupRequest, EditGroupResult>(gladstone, gladstone.editGroup)(input);
};

export const listRoles = (input: ListRolesRequest) => {
  return basicCallWrapper<ListRolesRequest, ListRolesResult>(gladstone, gladstone.listRoles)(input);
};

export const isMemberOfGroupingMechanismId = (input: IsMemberOfGroupingMechanismIdRequest) => {
  return basicCallWrapper<IsMemberOfGroupingMechanismIdRequest, IsMemberOfGroupingMechanismIdResponse>(
    gladstone,
    gladstone.isMemberOfGroupingMechanismId,
  )(input);
};

export const getGroupBaselineInfo = (input: GetGroupBaselineInfoRequest) => {
  return basicCallWrapper<GetGroupBaselineInfoRequest, GetGroupBaselineInfoResult>(
    gladstone,
    gladstone.getGroupBaselineInfo,
  )(input);
};

// workspace Onboard related apis
export const createWorkspace = (input: CreateWorkspaceRequest) => {
  return basicCallWrapper<CreateWorkspaceRequest, CreateWorkspaceResult>(gladstone, gladstone.createWorkspace)(input);
};

export const syncWorkspace = (input: SyncWorkspaceRequest) => {
  return basicCallWrapper<SyncWorkspaceRequest, SyncWorkspaceResult>(gladstone, gladstone.syncWorkspace)(input);
};

export const auditWorkspace = (input: AuditWorkspaceRequest) => {
  return basicCallWrapper<AuditWorkspaceRequest, AuditWorkspaceResult>(gladstone, gladstone.auditWorkspace)(input);
};

export const editWorkspace = (input: EditWorkspaceRequest) => {
  return basicCallWrapper<EditWorkspaceRequest, EditWorkspaceResult>(gladstone, gladstone.editWorkspace)(input);
};

export const getWorkspace = (input: GetWorkspaceRequest) => {
  return basicCallWrapper<GetWorkspaceRequest, GetWorkspaceResult>(gladstone, gladstone.getWorkspace)(input);
};

export const listWorkspaces = (input: ListWorkspacesRequest) => {
  return basicCallWrapper<ListWorkspacesRequest, ListWorkspacesResult>(gladstone, gladstone.listWorkspaces)(input);
};

export const getDataPermission = (input: GetDataPermissionRequest) => {
  return basicCallWrapper<GetDataPermissionRequest, GetDataPermissionResult>(
    gladstone,
    gladstone.getDataPermission,
  )(input);
};

export const getDataLink = (input: GetDataLinkRequest) => {
  return basicCallWrapper<GetDataLinkRequest, GetDataLinkResult>(gladstone, gladstone.getDataLink)(input);
};

export const getWorkspacesForGroups = (input: GetWorkspacesByGroupIdRequest) => {
  return basicCallWrapper<GetWorkspacesByGroupIdRequest, GetWorkspacesByGroupIdResult>(
    gladstone,
    gladstone.getWorkspacesByGroupId,
  )(input);
};

export const getWorkspacesForSingleGroupMemoized = _.memoize(async function (groupId: string) {
  return await getWorkspacesForGroups({ groupIds: [groupId] });
});

export const getWorkspacesCredentials = (input: GetWorkspaceCredentialsRequest) => {
  return basicCallWrapper<GetWorkspaceCredentialsRequest, GetWorkspaceCredentialsResult>(
    gladstone,
    gladstone.getWorkspaceCredentials,
  )(input);
};

export const registerRedshiftCluster = (input: RegisterRedshiftClusterRequest) => {
  return basicCallWrapper<RegisterRedshiftClusterRequest, RegisterRedshiftClusterResult>(
    gladstone,
    gladstone.registerRedshiftCluster,
  )(input);
};

export const getSupportedServicesForWorkspace = (input: GetWorkspaceSupportedServicesRequest) => {
  return basicCallWrapper<GetWorkspaceSupportedServicesRequest, GetWorkspaceSupportedServicesResult>(
    gladstone,
    gladstone.getWorkspaceSupportedServices,
  )(input);
};

export const getDataPermissionRequests = (input: GetDataPermissionRequestsRequest) => {
  return basicCallWrapper<GetDataPermissionRequestsRequest, GetDataPermissionRequestsResult>(
    gladstone,
    gladstone.getDataPermissionRequests,
  )(input);
};

export const getTags = (input: GetTagsRequest) => {
  return basicCallWrapper<GetTagsRequest, GetTagsResult>(gladstone, gladstone.getTags)(input);
};

export const getTaggedResources = (input: GetTaggedResourcesRequest) => {
  return basicCallWrapper<GetTaggedResourcesRequest, GetTaggedResourcesResult>(
    gladstone,
    gladstone.getTaggedResources,
  )(input);
};

export const getTagSharedWorkspaces = (input: GetTagSharedWorkspacesRequest) => {
  return basicCallWrapper<GetTagSharedWorkspacesRequest, GetTagSharedWorkspacesResult>(
    gladstone,
    gladstone.getTagSharedWorkspaces,
  )(input);
};

export const createTag = (input: CreateTagRequest) => {
  return basicCallWrapper<CreateTagRequest, CreateTagResult>(gladstone, gladstone.createTag)(input);
};

export const editTag = (input: EditTagRequest) => {
  return basicCallWrapper<EditTagRequest, EditTagResult>(gladstone, gladstone.editTag)(input);
};

export const listTemplates = (input: ListTemplatesRequest) => {
  return basicCallWrapper<ListTemplatesRequest, ListTemplatesResult>(gladstone, gladstone.listTemplates)(input);
};
export const createTemplate = (input: CreateTemplateRequest) => {
  return basicCallWrapper<CreateTemplateRequest, CreateTemplateResult>(gladstone, gladstone.createTemplate)(input);
};
export const associateTemplateToResources = (input: AssociateTemplateToResourcesRequest) => {
  return basicCallWrapper<AssociateTemplateToResourcesRequest, AssociateTemplateToResourcesResult>(
    gladstone,
    gladstone.associateTemplateToResources,
  )(input);
};
export const getTemplate = (input: GetTemplateRequest) => {
  return basicCallWrapper<GetTemplateRequest, GetTemplateResult>(gladstone, gladstone.getTemplate)(input);
};
export const editTemplate = (input: EditTemplateRequest) => {
  return basicCallWrapper<EditTemplateRequest, EditTemplateResult>(gladstone, gladstone.editTemplate)(input);
};
export const deleteTemplate = (input: DeleteTemplateRequest) => {
  return basicCallWrapper<DeleteTemplateRequest, DeleteTemplateResult>(gladstone, gladstone.deleteTemplate)(input);
};
export const dissociateTemplateFromResources = (input: DissociateTemplateFromResourcesRequest) => {
  return basicCallWrapper<DissociateTemplateFromResourcesRequest, DissociateTemplateFromResourcesResult>(
    gladstone,
    gladstone.dissociateTemplateFromResources,
  )(input);
};
export const getTemplateForResource = (input: GetTemplateForResourceRequest) => {
  return basicCallWrapper<GetTemplateForResourceRequest, GetTemplateForResourceResult>(
    gladstone,
    gladstone.getTemplateForResource,
  )(input);
};

export const validatePrerequisites = (input: ValidatePrerequisitesRequest) => {
  return basicCallWrapper<ValidatePrerequisitesRequest, ValidatePrerequisitesResult>(
    gladstone_no_retries,
    gladstone_no_retries.validatePrerequisites,
  )(input);
};

export const search = (input: SearchRequest) => {
  return basicCallWrapper<SearchRequest, SearchResult>(gladstone, gladstone.search)(input);
};

export const listFgaPolicies = (input: ListFineGrainAccessPolicyRequest) => {
  return basicCallWrapper<ListFineGrainAccessPolicyRequest, ListFineGrainAccessPolicyResult>(
    gladstone,
    gladstone.listFineGrainAccessPolicy,
  )(input);
};

export const getFgaPolicy = (input: GetFineGrainAccessPolicyRequest) => {
  return basicCallWrapper<GetFineGrainAccessPolicyRequest, GetFineGrainAccessPolicyResult>(
    gladstone,
    gladstone.getFineGrainAccessPolicy,
  )(input);
};

export const createFgaPolicy = (input: CreateFineGrainAccessPolicyRequest) => {
  return basicCallWrapper<CreateFineGrainAccessPolicyRequest, CreateFineGrainAccessPolicyResult>(
    gladstone,
    gladstone.createFineGrainAccessPolicy,
  )(input);
};

export const editFgaPolicy = (input: EditFineGrainAccessPolicyRequest) => {
  return basicCallWrapper<EditFineGrainAccessPolicyRequest, EditFineGrainAccessPolicyResult>(
    gladstone,
    gladstone.editFineGrainAccessPolicy,
  )(input);
};

export const deleteFgaPolicy = (input: DeleteFineGrainAccessPolicyRequest) => {
  return basicCallWrapper<DeleteFineGrainAccessPolicyRequest, DeleteFineGrainAccessPolicyResult>(
    gladstone,
    gladstone.deleteFineGrainAccessPolicy,
  )(input);
};

export const listDataZoneEntities = (input: ListDataZoneEntitiesRequest) => {
  return basicCallWrapper<ListDataZoneEntitiesRequest, ListDataZoneEntitiesResult>(
    gladstone,
    gladstone.listDataZoneEntities,
  )(input);
};

export const startGladstoneWorkflow = (input: StartGladstoneWorkflowRequest) => {
  return basicCallWrapper<StartGladstoneWorkflowRequest, StartGladstoneWorkflowResult>(
    gladstone,
    gladstone.startGladstoneWorkflow,
  )(input);
};

export const getGladstoneWorkflow = (input: GetGladstoneWorkflowRequest) => {
  return basicCallWrapper<GetGladstoneWorkflowRequest, GetGladstoneWorkflowResult>(
    gladstone,
    gladstone.getGladstoneWorkflow,
  )(input);
};
export const getExternalGroups = (input: GetExternalGroupsRequest) => {
  return basicCallWrapper<GetExternalGroupsRequest, GetExternalGroupsResult>(
    gladstone,
    gladstone.getExternalGroups,
  )(input);
};
