import {
  DATA_PERMISSION_DZ_TYPE,
  DATA_PERMISSION_IAM_TYPE,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  DGS_SERVICE_NAME_GLADSTONE,
  DZ_GLUE_DATASOURCE_ID,
  DZ_REDSHIFT_DATASOURCE_ID,
  IAM_DATASOURCE_ID,
  LAKE_FORMATION_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
} from 'src/commons/constants';
import { generateSchemaId } from 'src/components/utils/hybridCatalog/idUtil';
import { getSchemas, listCatalogs, listDatabases, listDataSets } from 'src/api/catalog';
import {
  createDatasetDetailLink,
  createCatalogDetailLink,
  createGlueDatabaseDetailLink,
  createSchemaDetailLink,
  createRedshiftDatabaseDetailLink,
} from 'src/routes';

export const HC_RESOURCE_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc:.+\\:\\d{12}:(glue|glueLF|redshift)[\\/A-Za-z0-9_-]*';

export const HC_CATALOG_ARN_REGEX = 'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:(glue|glueLF)';
export const HC_DATABASE_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:(glue|gluLF)/database/.+$';
export const HC_DATASET_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:(glue|glueLF)/database/.+/table/.+$';
export const HC_COLUMN_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:(glue|glueLF)/database/.+/table/.+/column/.+$';

export const HC_REDSHIFT_CATALOG_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/cluster/.+$';
export const HC_REDSHIFT_SRLS_CATALOG_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/serverless/.+$';
export const HC_REDSHIFT_DATABASE_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/cluster/.+/database/.+$';
export const HC_REDSHIFT_SRLS_DATABASE_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/serverless/.+/database/.+$';
export const HC_REDSHIFT_SCHEMA_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/cluster/.+/database/.+/schema/.+$';
export const HC_REDSHIFT_SRLS_SCHEMA_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/serverless/.+/database/.+/schema/.+$';
export const HC_REDSHIFT_DATASET_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/cluster/.+/database/.+/schema/.+/table/.+$';
export const HC_REDSHIFT_SRLS_DATASET_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/serverless/.+/database/.+/schema/.+/table/.+$';
export const HC_REDSHIFT_COLUMN_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/cluster/.+/database/.+/schema/.+/table/.+/column/.+$';
export const HC_REDSHIFT_SRLS_COLUMN_ARN_REGEX =
  'arn\\:(aws|aws-cn|aws-iso|aws-iso-b)\\:dgs-hc\\:.+\\:\\d{12}\\:redshift/serverless/.+/database/.+/schema/.+/table/.+/column/.+$';

export const AWS = 'aws';
export const CN_NORTHWEST_1 = 'cn-northwest-1';
export const AWS_CN = 'aws-cn';
export const US_ISO_EAST_1 = 'us-iso-east-1';
export const AWS_ISO = 'aws-iso';
export const US_ISOB_EAST_1 = 'us-isob-east-1';
export const AWS_ISO_B = 'aws-iso-b';
export const EU_ISOE_WEST_1 = 'eu-isoe-west-1';
export const AWS_ISO_E = 'aws-iso-e';
export const US_ISOF_SOUTH_1 = 'us-isof-south-1';
export const AWS_ISO_F = 'aws-iso-f';

export interface IdInfo {
  dataSource: string;
  catalogId: string;
  clusterId?: string;
  redshiftWorkgroupName?: string;
  databaseName?: string;
  schemaName?: string;
  tableName?: string;
  columnName?: string;
  region: string;
}

export enum Scope {
  Catalog = 'Catalog',
  Database = 'Database',
  Schema = 'Schema',
  Table = 'Table',
  Column = 'Column',
}

export function isRedshift(idInfo: IdInfo) {
  return idInfo.dataSource == REDSHIFT_DATASOURCE_ID || idInfo.dataSource == DZ_REDSHIFT_DATASOURCE_ID;
}

export function isServerless(idInfo: IdInfo) {
  return idInfo.redshiftWorkgroupName != undefined;
}

export function getScopeForIdInfo(idInfo: IdInfo) {
  if (idInfo.columnName) {
    return Scope.Column;
  } else if (idInfo.tableName) {
    return Scope.Table;
  } else if (idInfo.schemaName) {
    return Scope.Schema;
  } else if (idInfo.databaseName) {
    return Scope.Database;
  } else {
    return Scope.Catalog;
  }
}

export function getResourceNameForIdInfo(idInfo: IdInfo) {
  const scope = getScopeForIdInfo(idInfo);
  if (scope == Scope.Catalog) {
    return idInfo.catalogId;
  } else if (scope == Scope.Database) {
    return idInfo.databaseName;
  } else if (scope == Scope.Schema) {
    return idInfo.schemaName;
  } else if (scope == Scope.Table) {
    return idInfo.tableName;
  } else if (scope == Scope.Column) {
    return idInfo.columnName;
  } else {
    return '';
  }
}

export function getScopeForId(id: string) {
  const idInfo = getIdInfoFromId(id);
  return getScopeForIdInfo(idInfo);
}

export function getScopeForArn(arn: string) {
  const idInfo = getIdInfoFromArn(arn);
  return getScopeForIdInfo(idInfo);
}

export function extractAttributeFromId(attributeName: string, id: string) {
  // special case: DS- is always first
  if (attributeName == 'DS') {
    return id.split('|')[0].substring(3);
  }
  if (!id.includes('|' + attributeName + '-')) {
    return; // return undefined if the attribute does not exist
  }
  return id.split('|' + attributeName + '-')[1].split('|')[0];
}

export function extractAttributeFromArn(attributeName: string, arn: string) {
  if (!arn.includes('/' + attributeName + '/')) return undefined;
  return arn.split('/' + attributeName + '/')[1].split('/')[0];
}

export function getIdInfoFromId(id: string) {
  return {
    dataSource: extractAttributeFromId('DS', id),
    catalogId: extractAttributeFromId('A', id),
    clusterId: extractAttributeFromId('CI', id),
    redshiftWorkgroupName: extractAttributeFromId('WN', id),
    databaseName: extractAttributeFromId('DN', id),
    schemaName: extractAttributeFromId('SN', id),
    tableName: extractAttributeFromId('TN', id),
    columnName: extractAttributeFromId('CN', id),
    region: extractAttributeFromId('R', id),
  } as IdInfo;
}

export function getIdInfoFromArn(arn: string) {
  // example arn: arn:aws:dgs-hc:us-west-2:375937567384:redshift/cluster/redshift-test/database/awsdw/schema/awsdl_processed/table/o_aws_regions

  const region = arn.split(':')[3];
  const dataSource = arn.split(':')[5]?.split('/')[0];
  const catalogId = arn.split(':')[4];
  const clusterId = extractAttributeFromArn('cluster', arn);
  const redshiftWorkgroupName = extractAttributeFromArn('serverless', arn);
  const databaseName = extractAttributeFromArn('database', arn);
  const schemaName = extractAttributeFromArn('schema', arn);
  const tableName = extractAttributeFromArn('table', arn);
  const columnName = extractAttributeFromArn('column', arn);

  return {
    dataSource: dataSource,
    catalogId: catalogId,
    clusterId: clusterId,
    redshiftWorkgroupName: redshiftWorkgroupName,
    databaseName: databaseName,
    schemaName: schemaName,
    tableName: tableName,
    columnName: columnName,
    region: region,
  } as IdInfo;
}

export function hcArnPrefix(idInfo: IdInfo) {
  return `arn:${getAwsPartitionForRegion(idInfo.region)}:dgs-hc:${idInfo.region}:${idInfo.catalogId}:${
    idInfo.dataSource
  }`;
}

export function hcIdPrefix(idInfo: IdInfo) {
  return `DS-${idInfo.dataSource}|A-${idInfo.catalogId}`;
}

export function generateHcGlueDatasetArn(idInfo: IdInfo) {
  return hcArnPrefix(idInfo) + `/database/${idInfo.databaseName}/table/${idInfo.tableName}`;
}

export function generateGlueDatasetArn(idInfo: IdInfo) {
  return (
    `arn:${getAwsPartitionForRegion(idInfo.region)}:glue:${idInfo.region}:${idInfo.catalogId}:` +
    `table/${idInfo.databaseName}/${idInfo.tableName}`
  );
}

export function generateHcGlueDatasetId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|DN-${idInfo.databaseName}|TN-${idInfo.tableName}|R-${idInfo.region}`;
}

export function generateHcGlueDatabaseArn(idInfo: IdInfo) {
  return hcArnPrefix(idInfo) + `/database/${idInfo.databaseName}`;
}

export function generateGlueDatabaseArn(idInfo: IdInfo) {
  return (
    `arn:${getAwsPartitionForRegion(idInfo.region)}:glue:${idInfo.region}:${idInfo.catalogId}:` +
    `database/${idInfo.databaseName}`
  );
}

export function generateHcGlueDatabaseId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|DN-${idInfo.databaseName}|R-${idInfo.region}`;
}

export function generateHcGlueCatalogArn(idInfo: IdInfo) {
  return `arn:${getAwsPartitionForRegion(idInfo.region)}:dgs-hc:${idInfo.region}:${idInfo.catalogId}:${
    idInfo.dataSource
  }`;
}

export function generateHcGlueCatalogId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|R-${idInfo.region}`;
}

// REDSHIFT ARNS AND IDS

export function generateHcRedshiftColumnArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessDatasetArn(idInfo) + `/column/${idInfo.columnName}`;
}

export function generateHcRedshiftServerlessColumnArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessDatasetArn(idInfo) + `/column/${idInfo.columnName}`;
}

export function generateHcRedshiftDatasetArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessSchemaArn(idInfo) + `/table/${idInfo.tableName}`;
}

export function generateHcRedshiftServerlessDatasetArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessSchemaArn(idInfo) + `/table/${idInfo.tableName}`;
}

export function generateHcRedshiftDatasetId(idInfo: IdInfo) {
  return (
    hcIdPrefix(idInfo) +
    `|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|TN-${idInfo.tableName}|R-${idInfo.region}`
  );
}

export function generateHcRedshiftServerlessDatasetId(idInfo: IdInfo) {
  return (
    hcIdPrefix(idInfo) +
    `|WN-${idInfo.redshiftWorkgroupName}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|TN-${idInfo.tableName}|R-${idInfo.region}`
  );
}

export function generateHcRedshiftSchemaArn(idInfo: IdInfo) {
  return generateHcRedshiftDatabaseArn(idInfo) + `/schema/${idInfo.schemaName}`;
}

export function generateHcRedshiftServerlessSchemaArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessDatabaseArn(idInfo) + `/schema/${idInfo.schemaName}`;
}

export function generateHcRedshiftSchemaId(idInfo: IdInfo) {
  return (
    hcIdPrefix(idInfo) + `|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|R-${idInfo.region}`
  );
}

export function generateHcRedshiftServerlessSchemaId(idInfo: IdInfo) {
  return (
    hcIdPrefix(idInfo) +
    `|WN-${idInfo.redshiftWorkgroupName}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|R-${idInfo.region}`
  );
}

export function generateHcRedshiftDatabaseArn(idInfo: IdInfo) {
  return generateHcRedshiftCatalogArn(idInfo) + `/database/${idInfo.databaseName}`;
}

export function generateHcRedshiftServerlessDatabaseArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessCatalogArn(idInfo) + `/database/${idInfo.databaseName}`;
}

export function generateHcRedshiftDatabaseId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|R-${idInfo.region}`;
}

export function generateHcRedshiftServerlessDatabaseId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|WN-${idInfo.redshiftWorkgroupName}|DN-${idInfo.databaseName}|R-${idInfo.region}`;
}

export function generateHcRedshiftCatalogArn(idInfo: IdInfo) {
  return hcArnPrefix(idInfo) + `/cluster/${idInfo.clusterId}`;
}

export function generateHcRedshiftServerlessCatalogArn(idInfo: IdInfo) {
  return hcArnPrefix(idInfo) + `/serverless/${idInfo.redshiftWorkgroupName}`;
}

export function generateHcRedshiftCatalogId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|R-${idInfo.region}`;
}

export function generateHcRedshiftServerlessCatalogId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|R-${idInfo.region}`;
}

export function generateGlueArnFromId(id: string) {
  const scope = getScopeForId(id);
  const idInfo = getIdInfoFromId(id);

  if (scope == Scope.Table) {
    return generateGlueDatasetArn(idInfo);
  } else if (scope == Scope.Database) return generateGlueDatabaseArn(idInfo);
  return '';
}

export function generateArnFromId(id: string) {
  const scope = getScopeForId(id);
  const idInfo = getIdInfoFromId(id);
  const dataSource = idInfo.dataSource;

  if (scope == Scope.Table) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessDatasetArn(idInfo);
      } else {
        return generateHcRedshiftDatasetArn(idInfo);
      }
    } else {
      return generateHcGlueDatasetArn(idInfo);
    }
  } else if (scope == Scope.Database) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessDatabaseArn(idInfo);
      } else {
        return generateHcRedshiftDatabaseArn(idInfo);
      }
    } else {
      return generateHcGlueDatabaseArn(idInfo);
    }
  } else if (scope == Scope.Catalog) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessCatalogArn(idInfo);
      } else {
        return generateHcRedshiftCatalogArn(idInfo);
      }
    } else {
      return generateHcGlueCatalogArn(idInfo);
    }
  } else if (scope == Scope.Schema) {
    if (isServerless(idInfo)) {
      return generateHcRedshiftServerlessSchemaArn(idInfo);
    } else {
      return generateHcRedshiftSchemaArn(idInfo);
    }
  }
  return '';
}

export function generateIdFromArn(arn: string) {
  const scope = getScopeForArn(arn);
  const idInfo = getIdInfoFromArn(arn);

  if (scope == Scope.Table) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessDatasetId(idInfo);
      } else {
        return generateHcRedshiftDatasetId(idInfo);
      }
    } else {
      return generateHcGlueDatasetId(idInfo);
    }
  } else if (scope == Scope.Database) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessDatabaseId(idInfo);
      } else {
        return generateHcRedshiftDatabaseId(idInfo);
      }
    } else {
      return generateHcGlueDatabaseId(idInfo);
    }
  } else if (scope == Scope.Catalog) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessCatalogId(idInfo);
      } else {
        return generateHcRedshiftCatalogId(idInfo);
      }
    } else {
      return generateHcGlueCatalogId(idInfo);
    }
  } else if (scope == Scope.Schema) {
    if (isServerless(idInfo)) {
      return generateHcRedshiftServerlessSchemaId(idInfo);
    } else {
      return generateHcRedshiftSchemaId(idInfo);
    }
  }
  return '';
}

export function getAwsPartitionForRegion(region: string) {
  switch (region) {
    case CN_NORTHWEST_1:
      return AWS_CN;
    case US_ISO_EAST_1:
      return AWS_ISO;
    case US_ISOB_EAST_1:
      return AWS_ISO_B;
    case EU_ISOE_WEST_1:
      return AWS_ISO_E;
    case US_ISOF_SOUTH_1:
      return AWS_ISO_F;
    default:
      return AWS;
  }
}

export function convertResourceARNToLink(arn: string) {
  const scope = getScopeForArn(arn);
  const idInfo = getIdInfoFromArn(arn);

  if (scope == Scope.Catalog) {
    return createCatalogDetailLink(idInfo.catalogId, undefined, undefined, idInfo.region);
  }
  if (scope == Scope.Database) {
    if (isRedshift(idInfo)) {
      createRedshiftDatabaseDetailLink(
        idInfo.catalogId,
        idInfo.clusterId,
        idInfo.redshiftWorkgroupName,
        idInfo.databaseName,
        idInfo.region,
      );
    }
    return createGlueDatabaseDetailLink(idInfo.catalogId, idInfo.databaseName, idInfo.region);
  }
  if (scope == Scope.Table) {
    const datasetId = isRedshift(idInfo) ? generateHcRedshiftDatasetId(idInfo) : generateHcGlueDatasetId(idInfo);
    return createDatasetDetailLink(datasetId);
  }
  if (scope == Scope.Schema) {
    return createSchemaDetailLink(
      idInfo.catalogId,
      idInfo.clusterId,
      idInfo.redshiftWorkgroupName,
      idInfo.databaseName,
      idInfo.schemaName,
      idInfo.region,
    );
  }
  return '';
}

// TODO - update this for DZ / serverless resources
export const convertResourceArnToDpResource = (arn: string) => {
  const idInfo: IdInfo = getIdInfoFromArn(arn);
  const id: string = generateIdFromArn(arn);
  const scope: string = getScopeForArn(arn);

  const typeForScope = (scope: String) => {
    if (scope == Scope.Table) {
      return 'TABLE';
    } else if (scope == Scope.Database) {
      return 'DATABASE';
    } else if (scope == Scope.Schema) {
      return 'SCHEMA';
    }
    return '';
  };

  const dpTypeForDataSource = (dataSource: string) => {
    if (dataSource == IAM_DATASOURCE_ID) {
      return DATA_PERMISSION_IAM_TYPE;
    } else if (dataSource == LAKE_FORMATION_DATASOURCE_ID) {
      return DATA_PERMISSION_LAKE_FORMATION_TYPE;
    } else if (dataSource == REDSHIFT_DATASOURCE_ID) {
      return DATA_PERMISSION_REDSHIFT_TYPE;
    } else if (dataSource == DZ_REDSHIFT_DATASOURCE_ID || dataSource == DZ_GLUE_DATASOURCE_ID) {
      return DATA_PERMISSION_DZ_TYPE;
    }
    return '';
  };

  const resource = {
    accountId: idInfo.catalogId,
    region: idInfo.region,
    type: typeForScope(scope),
    dataCatalogObjectDetails: {
      dataSourceId: idInfo.dataSource,
      clusterIdentifier: idInfo.clusterId,
      databaseName: idInfo.databaseName,
      schemaName: idInfo.schemaName,
      tableName: idInfo.tableName,
    },
    dpType: dpTypeForDataSource(idInfo.dataSource),
    tagResourceId: id,
    tagUpperLevelResourceId:
      idInfo.dataSource === LAKE_FORMATION_DATASOURCE_ID ? generateHcGlueDatabaseId(idInfo) : generateSchemaId(idInfo),
  };
  console.log('resource ', resource);
  return resource;
};

// TODO
export const userOwnsResource = async (userInfo: any, idInfo: IdInfo) => {
  const scope = getScopeForIdInfo(idInfo);
  let owners = [];
  try {
    if (scope == Scope.Table) {
      const id = isRedshift(idInfo)
        ? isServerless(idInfo)
          ? generateHcRedshiftServerlessDatasetId(idInfo)
          : generateHcRedshiftDatasetId(idInfo)
        : generateHcGlueDatasetId(idInfo);
      const dataSetResult = await listDataSets({
        Filter: {
          IdList: [id],
        },
      });
      owners = dataSetResult.DataSetList[0].Owners;
      owners.push(dataSetResult.DataSetList[0].PrimaryOwner);
    } else if (scope == Scope.Database) {
      const databaseResult = await listDatabases({
        DatabaseKeyList: [
          {
            CatalogId: idInfo.catalogId,
            DatabaseName: idInfo.databaseName,
            ClusterIdentifier: idInfo.clusterId,
            RedshiftWorkgroupName: idInfo.redshiftWorkgroupName,
            Region: idInfo.region,
            DataSourceId: idInfo.dataSource,
          },
        ],
      });
      owners = databaseResult.DatabaseInfoList[0].Owners;
    } else if (scope == Scope.Catalog) {
      const catalogResult = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: idInfo.catalogId,
              Region: idInfo.region,
              ClusterIdentifier: idInfo.clusterId,
              RedshiftWorkgroupName: idInfo.redshiftWorkgroupName,
              DataSourceId: idInfo.dataSource,
            },
          ],
        },
      });
      owners = [catalogResult.CatalogInfoList[0].Owner];
    } else if (scope == Scope.Schema) {
      const schemaResult = await getSchemas({
        SchemaKeyList: [
          {
            CatalogId: idInfo.catalogId,
            DatabaseName: idInfo.databaseName,
            Schema: idInfo.schemaName,
            DataSourceId: idInfo.dataSource,
            Region: idInfo.region,
            ClusterIdentifier: idInfo.clusterId,
            RedshiftWorkgroupName: idInfo.redshiftWorkgroupName,
          },
        ],
      });
      owners = schemaResult.SchemaInfoList[0].Owners;
    }
  } catch (e) {
    console.log('Failed to fetch ownership for idInfo: ', idInfo, e);
    return false;
  }

  const groupOwner = userInfo?.memberGroupIds?.some((e) => owners.includes(e));
  const workspaceOwner = userInfo?.memberWorkspaceIds?.some((e) => owners.includes(e));
  return groupOwner || workspaceOwner;
};

// Gladstone
export function generateFGAPArn(policy) {
  if (!policy) return null;

  const policyId = policy.id;
  const tableData = policy.tableData;

  if (tableData.clusterId) {
    return (
      `arn:${getAwsPartitionForRegion(tableData.region)}:${DGS_SERVICE_NAME_GLADSTONE}:${tableData.region}:${
        tableData.tableCatalogId
      }:${REDSHIFT_DATASOURCE_ID}` +
      `/cluster/${tableData.clusterId}/database/${tableData.databaseName}/schema/${tableData.schemaName}/table/${tableData.tableName}/fine-grained-access-policy/${policyId}`
    );
  }

  return (
    `arn:${getAwsPartitionForRegion(tableData.region)}:${DGS_SERVICE_NAME_GLADSTONE}:${tableData.region}:${
      tableData.tableCatalogId
    }:${LAKE_FORMATION_DATASOURCE_ID}` +
    `/database/${tableData.databaseName}/table/${tableData.tableName}/fine-grained-access-policy/${policyId}`
  );
}
