import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { isValidMidwayUser } from 'src/commons/validationUtils';
import { getPhoneToolLink } from 'src/commons/common';

export const iamColumnDefinition = (isIncomingRequests): TableProps.ColumnDefinition<any>[] => [
  {
    id: 'catalogId',
    header: 'Catalog ID',
    cell: (item) => item.catalogId,
    minWidth: '125px',
    sortingField: 'catalogId',
  },
  {
    id: 'databaseName',
    header: 'Database name',
    cell: (item) => item.databaseName,
    minWidth: '200px',
    sortingField: 'databaseName',
  },
  {
    id: 'tableName',
    header: 'Table name',
    cell: (item) => item.tableName,
    minWidth: '200px',
    sortingField: 'tableName',
  },
  isIncomingRequests
    ? {
        id: 'requestedBy',
        header: 'Requested by',
        cell: (item) => item.groupRequestedBy,
        minWidth: '100px',
        sortingField: 'groupRequestedBy',
      }
    : {
        id: 'requestedTo',
        header: 'Requested to',
        cell: (item) => item.groupRequestedTo,
        minWidth: '100px',
        sortingField: 'groupRequestedTo',
      },
  {
    id: 'requestedTime',
    header: 'Requested time',
    cell: (item) => item.timeOfRequest,
    minWidth: '100px',
    sortingField: 'timeOfRequest',
  },
  {
    id: 'requester',
    header: 'Requester',
    cell: (item) => (isValidMidwayUser(item.requester) ? getPhoneToolLink(item.requester) : item.requester),
    minWidth: '100px',
    sortingField: 'requester',
  },
];

export const extractAccessManagementInfoFromRequest = (item, transform) => {
  if (item.additionalMetadata != undefined && item.additionalMetadata.accessManagementInfoList != undefined) {
    let allSimTicketLinks = [];
    let approvals = [];
    for (const accessManagementInfo of item.additionalMetadata.accessManagementInfoList) {
      if (accessManagementInfo.simTicketLinks) {
        allSimTicketLinks = allSimTicketLinks.concat(
          accessManagementInfo?.simTicketLinks?.map((simTemplate) => {
            return {
              simTemplateLink: simTemplate.simTemplateLink,
              simTemplateName: simTemplate.simTemplateName,
              simTicketLink: simTemplate.simTicketLink,
            };
          }),
        );
      }
      if (accessManagementInfo.approvalTemplateId) {
        approvals = approvals.concat({
          approvalTemplateId: accessManagementInfo.approvalTemplateId,
          approvalWorkflowId: accessManagementInfo.approvalWorkflowId,
          approvalWorkflowStatus: accessManagementInfo.approvalWorkflowStatus,
        });
      }
    }
    if (allSimTicketLinks.length > 0) {
      transform['simTickets'] = allSimTicketLinks;
    }
    if (approvals.length > 0) {
      transform['approvalsWorkflow'] = approvals;
    }
  }
  if (item.additionalMetadata != undefined && item.additionalMetadata.dataZoneMetadata != undefined) {
    transform['dataZoneAssetId'] = item.additionalMetadata.dataZoneMetadata.assetId;
    transform['dataZoneProjectId'] = item.additionalMetadata.dataZoneMetadata.projectId;
  }
  return transform;
};
