import * as React from 'react';
import { useState, useEffect } from 'react';

import { Container, SpaceBetween, Header } from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { listDataZoneEntities } from 'src/api/permissions';
import { DZEntity } from 'aws-sdk/clients/awsdatalakegladstonelambda';

export interface ReviewAndSubmitContentProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
  groupId: string;
  workspaceId: string;
  clusterId: string;
  workgroupName: string;
}

export const ReviewAndSubmitContent = (props: ReviewAndSubmitContentProps) => {
  const [redirect, setRedirect] = useState<string>(undefined);

  let existingAccountEntities: DZEntity[] = [];
  let existingProjectEntities: DZEntity[] = [];
  let existingEnvironmentEntities: DZEntity[] = [];

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    existingProjectEntities = (
      await listDataZoneEntities({
        entityType: 'Project',
        groupId: props.groupId,
      })
    ).entities;
    existingAccountEntities = (
      await listDataZoneEntities({
        entityType: 'AssociatedAccount',
        groupId: props.groupId,
      })
    ).entities;
    existingEnvironmentEntities = (
      await listDataZoneEntities({
        entityType: 'Environment',
        groupId: props.groupId,
      })
    ).entities;
  };

  const entitiesToCreate = () => {
    if (!props.groupId || !props.workspaceId || (!props.clusterId && !props.workgroupName)) return <></>;
    const accountAssociationWillBeCreated: boolean =
      existingAccountEntities.filter((entity) => props.workspaceId.includes(entity.accountId)).length == 0;
    const projectWillBeCreated: boolean =
      existingProjectEntities.filter((entity) => entity.groupId == props.groupId).length == 0;
    const environmentWillBeCreated: boolean =
      existingEnvironmentEntities.filter((entity) => {
        if (props.workgroupName) {
          return (
            entity.groupId == props.groupId &&
            entity.environmentMetadata.redshiftInfo.workgroupName == props.workgroupName
          );
        } else {
          entity.groupId == props.groupId &&
            entity.environmentMetadata.redshiftInfo.clusterName == props.clusterId &&
            props.workspaceId.includes(entity.environmentMetadata.redshiftInfo.awsAccountId);
        }
      }).length == 0;

    const accountId = props.workspaceId.split('-')[1];

    return (
      <Container header={<Header variant={'h2'}>Review</Header>}>
        The following resources will be created:
        <ul>
          {accountAssociationWillBeCreated && (
            <li>
              A <b>RAM share</b> from the Omni-DataZone domain to account <b>{accountId}</b>.
            </li>
          )}
          {projectWillBeCreated && (
            <li>
              A <b>project</b> called <b>{props.groupId}</b>.
            </li>
          )}
          {environmentWillBeCreated && (
            <li>
              An <b>environment</b> for {props.workgroupName ? 'workgroup' : 'cluster'}{' '}
              <b>{props.workgroupName ? props.workgroupName : props.clusterId}</b> in project <b>{props.groupId}</b>.
            </li>
          )}
        </ul>
      </Container>
    );
  };

  if (redirect) {
    if (redirect) return <Redirect push to={redirect} />;
  }

  return (
    <SpaceBetween size={'l'} direction={'vertical'}>
      {entitiesToCreate()}
    </SpaceBetween>
  );
};
