/**
 * Gets the value(s) located at given (sub)key(s).
 * @param {object} obj - The oject containing the nested subkeys
 * @param {array<string | array<string>} subkeys - An ordered list of strings matching the keys nested within an object. NOTE: The last value within this array can be an array of strings in order to return multiple keys from the object.
 * @returns {any | array<any>} - The value(s) located at the given (sub)key(s)
 *
 * USAGE:
 * obj = {
 *    a: 1,
 *    b: 2,
 *    c: {
 *        d:{
 *            e: 5,
 *            f: 6,
 *            g: 7,
 *        },
 *    },
 * };
 *
 * e.g. get(obj, ["a"]) returns 1
 * e.g. get(obj, ["a", "c", "d"]) returns {e:5, f:6, g:7}
 * e.g. get(obj, ["a", "c", "d", ["e", "f", "g"]]) returns [5,6,7]
 */
export function get(obj, subkeys) {
  if (obj == undefined) {
    return undefined;
  }
  var sks = [...subkeys];
  const nextKey = sks.shift();
  if (nextKey == undefined) return obj;

  if (sks.length == 0) {
    if (Array.isArray(nextKey)) {
      return nextKey.map((key) => obj[key]);
    } else {
      return obj[nextKey];
    }
  } else {
    return get(obj[nextKey], sks);
  }
}

/**
 * Returns the value at a key from a given object and then deletes that key in the object, or if the key doesn't exist returns a user-defined default-value.
 * @param {object} obj
 * @param {string} key
 * @param {any} defaultVal
 * @returns {any} value of obj[key] or defaultValue, if the key doesn't exist
 */
export function pop(obj, key, defaultVal = undefined) {
  if (obj == undefined) {
    return undefined;
  }

  if (key in obj) {
    const val = obj[key];
    delete obj[key];
    return val;
  } else if (typeof defaultVal !== 'undefined') {
    return defaultVal;
  }
  throw `Key (${key}) not in object (${Object.keys(obj)}), no defaultVal defined`;
}

/**
 * Helper function which returns a flattened list of the recursively nested entries in an object.
 */
export const flattenObjEntries = (obj) => {
  return Object.entries(obj).reduce((keys, [key, value]) => {
    if (value.constructor === Object) {
      keys = keys.concat(flattenObjEntries(value));
    } else {
      keys.push([key, value]);
    }
    return keys;
  }, []);
};

export enum ViewType {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export const dateString = (date) => {
  return new Date(date).toString().replace(/ (\(.*\))$/, '');
};
