import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, ColumnLayout, Flashbar, Header, SpaceBetween, Tabs } from '@amzn/awsui-components-react-v3';

import {
  convertToDgsHCResourceArn,
  CopiableText,
  isDGSAdmin,
  convertToDgsHCResourceId,
  fetchTemplatesForResourceId,
  DefaultRouteProps,
} from 'src/commons/common';
import { DatabaseEditModal } from 'src/components/workspaces/dataBrowse/database/databaseEdit';
import { PageHeader } from 'src/components/notifications/common';
import { WSSchemaList } from 'src/components/workspaces/dataBrowse/database/schemaList';
import { listDatabases } from 'src/api/catalog';
import { enableAdvisories, getRegion } from 'src/api/config';
import {
  DATA_PERMISSION_REDSHIFT_TYPE,
  DZ_REDSHIFT_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { DataConsumersTable } from 'src/components/permissions/myBaselining/dataConsumersTable';
import BusinessGlossaries from 'src/components/workspaces/common/businessGlossaries';
import MetadataDetails from 'src/components/workspaces/common/metadataDetails';
import { AdvisoriesForResourceTable } from 'src/components/dataadvisory/listAdvisoriesPage/advisoriesForResource';
import { generateArnFromId } from 'src/components/utils/arnUtil';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import { ContactInfo } from 'src/components/workspaces/common/ContactInfo';
import { ownerLinkItem, redshiftIcon } from 'src/components/workspaces/common/common';
import { createCatalogDetailLink } from 'src/routes';

export interface WorkspaceDatabaseDetailProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  setActiveDatabaseName: any;
  cartItemIds: [];
  addToCart: any;
  catalogMap: any;
  userInfo: any;
}

export const WorkspaceRedshiftDatabaseDetail = (props: WorkspaceDatabaseDetailProps) => {
  const [database, setDatabase] = useState(undefined);
  const [editDatabaseModalVisible, setEditDatabaseModalVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [catalogName, setCatalogName] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState(undefined);

  const notifyEditSuccess = async (msg) => {
    setNotifications([
      {
        type: 'success',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };
  const notifyEditFailure = async (msg) => {
    setNotifications([
      {
        type: 'error',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    props.setActiveDatabaseName(props.match.params.databasename);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const databases = await listDatabases({
        DatabaseKeyList: [
          {
            CatalogId: props.match.params.catalogid,
            DatabaseName: props.match.params.databasename,
            DataSourceId: props.match.params.workgroupname ? DZ_REDSHIFT_DATASOURCE_ID : REDSHIFT_DATASOURCE_ID,
            ClusterIdentifier: props.match.params.clustername,
            RedshiftWorkgroupName: props.match.params.workgroupname,
            Region: getRegion(),
          },
        ],
      });
      let database = databases.DatabaseInfoList[0];
      setCatalogName(
        props.catalogMap.get(database?.CatalogId + ':' + database?.ClusterIdentifier + ':' + database?.Region),
      );
      await fetchTemplatesForDatabase(database);
      setDatabase(database);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    setLoading(false);
  };

  const getTemplateForDatabase = () => {
    return <TemplatesForResourceDetailsTable items={templates} userOwnsResource={userOwnsDatabase()} />;
  };

  const fetchTemplatesForDatabase = async (database) => {
    // TODO
    let resourceId = convertToDgsHCResourceId(
      database?.CatalogId,
      database?.ClusterIdentifier,
      database?.DatabaseName,
      undefined,
      undefined,
      undefined,
      database?.DataAccessRole,
    );
    let fetchedTemplates = await fetchTemplatesForResourceId(resourceId);
    setTemplates(fetchedTemplates);
  };

  const userOwnsDatabase = () => {
    if (!database || !database.Owners) return false;
    if (props.userInfo && isDGSAdmin(props.userInfo.memberGroupIds)) return true;
    if (props.activeWorkspace == null) {
      return false;
    }
    return database?.Owners.includes(props.activeWorkspace?.workspaceId);
  };

  const buildResource = () => {
    if (!database) return {};
    return {
      accountId: database.CatalogId,
      region: database.Region,
      type: 'DATABASE',
      dataCatalogObjectDetails: {
        dataSourceId: 'Redshift',
        clusterIdentifier: database.ClusterIdentifier,
        databaseName: database.DatabaseName,
      },
      dpType: DATA_PERMISSION_REDSHIFT_TYPE,
      tagResourceId: `DS-redshift|A-${database.CatalogId}|CI-${database.ClusterIdentifier}|DN-${database.DatabaseName}`,
    };
  };

  const setNotification = async (header, message) => {
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const getDatabaseDetailsMetadata = (props: any) => {
    const databaseDetails = [];
    if (database?.DatabaseName) {
      databaseDetails.push(
        <CopiableText name={'Database name'} key={'Database name'} value={database?.DatabaseName} />,
      );
    }
    if (database?.Description) {
      databaseDetails.push(<CopiableText name={'Description'} key={'Description'} value={database?.Description} />);
    }
    if (database?.CatalogId) {
      databaseDetails.push(
        <CopiableText
          name={'Catalog ID'}
          key={'Catalog ID'}
          value={database?.CatalogId}
          url={createCatalogDetailLink(database?.CatalogId, database?.ClusterIdentifier, undefined, database?.Region)}
        />,
      );
    }

    if (database?.Owners) {
      databaseDetails.push(
        <CopiableText
          copiable={false}
          name={'Owner'}
          key={'Owner'}
          value={database?.Owners.map((owner) => (
            <div>{ownerLinkItem(owner, props.workspaceNameMap)}</div>
          ))}
        />,
      );
    }

    if (database?.Region) {
      databaseDetails.push(<CopiableText name={'Region'} key={'Region'} value={database?.Region} />);
    }

    if (database?.ClusterIdentifier) {
      databaseDetails.push(
        <CopiableText name={'Cluster name'} key={'Cluster name'} value={database?.ClusterIdentifier} />,
      );
    }

    if (database?.RedshiftWorkgroupName) {
      databaseDetails.push(
        <CopiableText name={'Workgroup name'} key={'Workgroup name'} value={database?.RedshiftWorkgroupName} />,
      );
    }

    if (catalogName) {
      databaseDetails.push(
        <CopiableText
          name={'Catalog name'}
          key={'Catalog name'}
          value={catalogName}
          url={createCatalogDetailLink(database?.CatalogId, database?.ClusterIdentifier, undefined, database?.Region)}
        />,
      );
    }
    if (database?.CreatedBy) {
      databaseDetails.push(<CopiableText name={'Created by'} key={'CreatedBy'} value={database?.CreatedBy} />);
    }
    if (database?.CreatedOn) {
      databaseDetails.push(<CopiableText name={'Created on'} key={'CreatedOn'} value={database?.CreatedOn} />);
    }
    if (database?.UpdatedBy) {
      databaseDetails.push(<CopiableText name={'Updated by'} key={'UpdatedBy'} value={database?.UpdatedBy} />);
    }
    if (database?.UpdatedOn) {
      databaseDetails.push(<CopiableText name={'Updated on'} key={'UpdatedOn'} value={database?.UpdatedOn} />);
    }
    return databaseDetails;
  };

  const closeEditModal = () => {
    setEditDatabaseModalVisible(false);
  };

  const databaseDetail = () => {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: (
          <>
            <div className='awsui-util-container'>
              <div className='awsui-util-container-header'>
                <div className='awsui-util-action-stripe'>
                  <div className='awsui-util-action-stripe-title'>
                    <h2>Database details</h2>
                  </div>
                  <div className='awsui-util-action-stripe-group'>
                    <div style={{ paddingTop: 5, paddingRight: 10 }} hidden={!userOwnsDatabase()}>
                      <Button
                        variant='normal'
                        onClick={() => {
                          setEditDatabaseModalVisible(true);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <ColumnLayout columns={3} borders='horizontal'>
                {getDatabaseDetailsMetadata(props)}
              </ColumnLayout>
            </div>
            <SpaceBetween size={'l'}>
              {!loading && database && <ContactInfo resource={getArn()} />}

              {!loading && database && (
                <BusinessGlossaries
                  activeGroup={props.activeGroup}
                  activeWorkspace={props.activeWorkspace}
                  match={''}
                  setContentType={props.setContentType}
                  resource={convertToDgsHCResourceArn(
                    database?.CatalogId,
                    database?.ClusterIdentifier,
                    database?.DatabaseName,
                    undefined,
                    undefined,
                    undefined,
                    database?.DataAccessRole,
                  )}
                  isOwner={userOwnsDatabase()}
                />
              )}
            </SpaceBetween>
          </>
        ),
      },
      {
        label: 'Schemas',
        id: 'Schemas',
        content: (
          <WSSchemaList
            {...props}
            catalogId={props.match.params.catalogid}
            databaseName={props.match.params.databasename}
            schemas={database?.tables}
            clusterName={props.match.params.clustername}
            redshiftWorkgroupName={props.match.params.workgroupname}
            region={props.match.params.region}
          />
        ),
      },
      {
        label: 'Templates',
        id: 'Templates',
        content: getTemplateForDatabase(),
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: (
          <MetadataDetails
            resourceOwnerIds={database?.Owners}
            resource={convertToDgsHCResourceArn(
              database?.CatalogId,
              database?.ClusterIdentifier,
              database?.DatabaseName,
              undefined,
              undefined,
              undefined,
              database?.DataAccessRole,
            )}
            activeGroup={props.activeGroup}
            activeWorkspace={props.activeWorkspace}
            setContentType={props.setContentType}
            setNotification={setNotification}
          />
        ),
      },
    ];

    if (userOwnsDatabase())
      tabs.push({
        label: 'Consumers',
        id: 'consumers',
        content: <DataConsumersTable {...props} resource={buildResource()} />,
      });

    if (enableAdvisories()) {
      tabs.push({
        label: 'Advisories',
        id: 'advisories',
        content: (
          <AdvisoriesForResourceTable
            resourceArn={getArn()}
            activeGroup={props.activeGroup}
            setContentType={props.setContentType}
          />
        ),
      });
    }

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  const getArn = () => {
    // TODO
    const id = `DS-redshift|A-${database?.CatalogId}|CI-${database?.ClusterIdentifier}|DN-${database?.DatabaseName}|R-${database?.Region}`;
    const arn = generateArnFromId(id);
    return arn;
  };

  if (!database && !loading) {
    return (
      <>
        <h2>Database not found</h2>
        The given database is not valid, or you do not have permission to view it. Please check the URL for mistakes and
        try again.
      </>
    );
  }

  return (
    <>
      <Flashbar items={notifications} />

      <PageHeader
        buttons={[]}
        header={
          <Header description={database?.Description} variant={'h1'}>
            {redshiftIcon} {database?.DatabaseName}
          </Header>
        }
      />
      {databaseDetail()}

      {database !== undefined && (
        <DatabaseEditModal
          {...props}
          database={database}
          visible={editDatabaseModalVisible}
          dismiss={closeEditModal}
          notifyEditSuccess={notifyEditSuccess}
          notifyEditFailure={notifyEditFailure}
        />
      )}
    </>
  );
};
