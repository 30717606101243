import * as React from 'react';
import { MetricTableView } from 'src/components/metricstore/metriccollection/metricTableView';
import { ViewType } from 'src/components/metricstore/helpers';

export interface yearlyViewProps {
  setContentType: any;
  match: any;
}

export const YearlyView = (props: yearlyViewProps) => {
  return <MetricTableView props={props} viewType={ViewType.YEARLY} columnCount={5} />;
};
