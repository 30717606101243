import {
  Pagination,
  Table,
  CollectionPreferences,
  CollectionPreferencesProps,
  Flashbar,
  PropertyFilter,
  Modal,
  Button,
  Input,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import {
  auditDataPermission,
  deactivateDataPermission,
  getDataLink,
  getDataPermission,
  getTaggedResources,
} from 'src/api/permissions';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { TableResourceLink } from 'src/components/workspaces/accessibleData/resourceLink/tableResourceLink';
import { DatabaseResourceLink } from 'src/components/workspaces/accessibleData/resourceLink/databaseResourceLink';
import { Link, Redirect } from 'react-router-dom';
import { ResourceLinkTable } from 'src/components/workspaces/accessibleData/resourceLinks';
import {
  createFgaPolicyViewLink,
  createGroupPermissionLFLink,
  createDatasetDetailLink,
  createCatalogDetailLink,
  createGlueDatabaseDetailLink,
} from 'src/routes';
import {
  DATABASE_RESOURCE_TYPE,
  DATASET_RESOURCE_TYPE,
  LAKEFORMATION_TAG_TYPE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import {
  DataPermissionRAMResources,
  DataPermissionRAMResourceShare,
} from 'src/components/permissions/myDatasets/common';
import { dataPermissionDetail } from 'src/components/workspaces/common/common';
import { DEFAULT_CANCEL_REASON } from 'src/components/workspace_access/utils';
import { scrollUp } from 'src/components/utils/navigation';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';

export interface PermissionDetailProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  visible: boolean;
  match: any;
  dismiss: any;
  dataset: any;
  addToCart: any;
  catalogName: any;
  catalogMap: any;
}

export const LfPermissionDetail = (props: PermissionDetailProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [resourceLinkList, setResourceLinkList] = useState(undefined);
  const [dataPermission, setDataPermission] = useState(undefined);
  const [databaseResourceLinkModalVisible, setDatabaseResourceLinkModalVisible] = useState(false);
  const [datasetResourceLinkModalVisible, setDatasetResourceLinkModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [redirect, setRedirect] = useState(undefined);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false);
  const [deactivateReason, setDeactivateReason] = useState(undefined);
  const [deactivateRequestLoading, setDeactivateRequestLoading] = useState(false);
  const fetchTagResources = async (tagKey, tagValue, catalogId, region) => {
    let request = {
      tagPair: tagKey + ':' + tagValue,
      catalogId: catalogId,
      region: region,
      type: LAKEFORMATION_TAG_TYPE,
    };
    let resources = await getTaggedResources(request);
    let taggedResourceList = resources.resources;
    let curItems = [];
    let catalogName = props.catalogMap.get(catalogId + ':' + region);
    for (let taggedResource of taggedResourceList) {
      if (taggedResource.resourceType == DATABASE_RESOURCE_TYPE) {
        curItems.push({
          catalogId: taggedResource.catalogId,
          databaseName: taggedResource.resourceName,
          dataPermissionId: props.match.params.permissionId,
          catalogName: catalogName,
          resourceId: taggedResource.resourceId,
          region: region,
        });
      } else if (taggedResource.resourceType == DATASET_RESOURCE_TYPE) {
        curItems.push({
          catalogId: taggedResource.catalogId,
          databaseName: taggedResource.databaseName,
          tableName: taggedResource.resourceName,
          dataPermissionId: props.match.params.permissionId,
          catalogName: catalogName,
          resourceId: taggedResource.resourceId,
          region: region,
        });
      }
    }
    return curItems;
  };

  const columnDefinitions = [
    {
      id: 'catalogName',
      header: 'Catalog name',
      cell: (item) => (
        <Link to={createCatalogDetailLink(item?.catalogId, undefined, undefined, item?.region)}>
          {item?.catalogName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'database',
      header: 'Database name',
      cell: (item) => (
        <Link to={createGlueDatabaseDetailLink(item?.catalogId, item?.databaseName, item?.region)}>
          {item?.databaseName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'dataset',
      header: 'Dataset name',
      cell: (item) =>
        item.tableName == undefined ? (
          '*'
        ) : (
          <Link
            to={createDatasetDetailLink(
              `DS-glueLF|A-${item?.catalogId}|DN-${item?.databaseName}|TN-${item?.tableName}|R-${item?.region}`,
            )}
          >
            {item?.tableName}
          </Link>
        ),
      minWidth: 200,
    },
    {
      id: 'region',
      header: 'Region',
      cell: (item) => item.region,
      minWidth: 100,
    },
    {
      id: 'dataCellFilter',
      header: 'Fine grain policy',
      cell: (item) => (item.fgapId ? <Link to={createFgaPolicyViewLink(item.fgapId)}>{item?.fgapName}</Link> : ''),
      minWidth: 250,
    },
  ];

  const fetchDataPermission = async () => {
    try {
      const dataPermissionResult = await getDataPermission({
        dataPermissionId: props.match.params.permissionId,
        metadata: props.match.params.permissionId,
      });

      setDataPermission(dataPermissionResult?.DataPermission);
      let response = await getDataLink({
        dataPermissionId: props.match.params.permissionId,
      });
      if (response?.dataLink !== undefined && response.dataLink.length > 0) {
        return response.dataLink;
      }
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to get data permission ` + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    return undefined;
  };

  const handleRefresh = async () => {
    setLoadingResource(true);
    let permissionList = await fetchDataPermission();
    if (permissionList == undefined) {
      return;
    }
    let itemList = [];
    let rlList = [];
    for (let permission of permissionList) {
      if (permission.resource?.lFTagPolicy !== undefined) {
        let policy = permission.resource?.lFTagPolicy;
        let tagKey = policy.expression[0].tagKey;
        let tagValue = policy.expression[0].tagValues[0];
        let catalogId = policy.catalogId;
        let region = permission.region;
        let sharedResources = await fetchTagResources(tagKey, tagValue, catalogId, region);
        itemList.push(...sharedResources);
      } else if (permission.resource?.dataCellsFilter !== undefined) {
        let dataCellFilter = permission.resource?.dataCellsFilter;
        let catalogName = props.catalogMap.get(dataCellFilter.tableCatalogId + ':' + permission.region);
        itemList.push({
          catalogId: dataCellFilter.tableCatalogId,
          tableName: dataCellFilter.tableName,
          databaseName: dataCellFilter.databaseName,
          fgapName: dataCellFilter.name,
          fgapId: permission.fgapId,
          dataPermissionId: permission.dataPermissionId,
          catalogName: catalogName,
          region: permission.region,
        });
      } else if (permission.resource?.table !== undefined) {
        let table = permission.resource?.table;
        let catalogName = props.catalogMap.get(table.catalogId + ':' + permission.region);
        let catalogId = table.catalogId;
        let dataset = table.name;
        let database = table.databaseName;

        itemList.push({
          catalogId: catalogId,
          tableName: dataset,
          databaseName: database,
          dataPermissionId: props.match.params.permissionId,
          catalogName: catalogName,
          region: permission.region,
        });
      } else if (permission.resource?.glueTable !== undefined) {
        let glueTable = permission.resource?.glueTable;
        let sourceResource = glueTable?.targetTable;
        let catalogName = props.catalogMap.get(glueTable?.catalogId + ':' + permission.region);

        rlList.push({
          catalogId: glueTable?.catalogId,
          databaseName: glueTable?.databaseName,
          sourceCatalogId: sourceResource?.catalogId,
          tableName: glueTable?.name,
          sourceResource:
            props.catalogMap.get(sourceResource?.catalogId + ':' + sourceResource?.region) +
            '/' +
            sourceResource?.databaseName +
            '/' +
            sourceResource?.name +
            '/' +
            sourceResource?.region,
          catalogName: catalogName,
          region: permission.region,
        });
      } else if (permission.resource?.glueDatabase !== undefined) {
        let glueDatabase = permission.resource?.glueDatabase;
        let sourceResource = glueDatabase?.targetDatabase;
        let catalogName = props.catalogMap.get(glueDatabase?.catalogId + ':' + permission.region);

        rlList.push({
          catalogId: glueDatabase?.catalogId,
          databaseName: glueDatabase?.name,
          sourceCatalogId: sourceResource?.catalogId,
          sourceResource:
            props.catalogMap.get(sourceResource?.catalogId + ':' + permission.region) +
            '/' +
            sourceResource?.databaseName +
            '/*',
          catalogName: catalogName,
          region: permission.region,
        });
      }
    }
    setItems(itemList);
    setResourceLinkList(rlList);
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    if (!signedInWithWorkspace(props)) {
      setRedirect(createGroupPermissionLFLink(props.match.params.permissionId));
    }
  }, [props.activeWorkspace, props.activeGroup]);

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No data</b>
          </div>
          <p className='awsui-util-mb-s'>No databases/datasets were found in this permission.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Catalog name',
          key: 'catalogName',
          groupValuesLabel: 'Catalog names',
        },
        {
          propertyLabel: 'Database name',
          key: 'databaseName',
          groupValuesLabel: 'Database names',
        },
        {
          propertyLabel: 'Dataset name',
          key: 'tableName',
          groupValuesLabel: 'Dataset names',
        },
      ],
    },
  });

  const notifyCreateResourceLinkSuccess = async (msg) => {
    setNotifications([
      {
        type: 'success',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };
  const notifyCreateResourceLinkFailure = async (msg) => {
    setNotifications([
      {
        type: 'error',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const handleButtonAction = async (e) => {
    if (e.detail.id == 'audit') {
      await auditCurrentDataPermission();
    } else if (e.detail.id === 'deactivate') {
      setDeactivateModalVisible(true);
    }
  };

  const handleDeactivate = async () => {
    setDeactivateRequestLoading(true);

    let finalCancelReason = DEFAULT_CANCEL_REASON;
    if (deactivateReason !== '') {
      finalCancelReason = deactivateReason;
    }
    try {
      await deactivateDataPermission({
        dataPermissionId: props.match.params.permissionId,
        reasonOfAction: finalCancelReason,
      });
      await handleRefresh();
      setNotifications([
        {
          type: 'success',
          content: 'Data permission deactivated successfully',
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Data permission deactivation failed. Reason: ${err.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      console.log(err);
    }

    setDeactivateRequestLoading(false);
    setDeactivateReason('');
    setDeactivateModalVisible(false);
    scrollUp();
  };

  const auditCurrentDataPermission = async () => {
    setLoadingButton(true);
    try {
      const response = await auditDataPermission({
        dataPermissionId: props.match.params.permissionId,
      });
      if (response.auditStatus === 'SUCCEEDED') {
        setNotifications([
          {
            type: 'success',
            content: `Audit of ${props.match.params.permissionId} succeeded`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      } else {
        setNotifications([
          {
            type: 'error',
            content: `Audit of ${props.match.params.permissionId} failed`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      }
      await handleRefresh();
      setLoadingButton(false);
    } catch (err) {
      setLoadingButton(false);
      setNotifications([
        {
          type: 'error',
          content: `Audit of ${props.match.params.permissionId} failed because ${err.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const createResourceLink = async () => {
    let item = collectionProps.selectedItems[0];
    if (item.tableName == undefined) {
      setDatabaseResourceLinkModalVisible(true);
    } else {
      setDatasetResourceLinkModalVisible(true);
    }
  };

  useEffect(() => {
    const { selectedItems } = collectionProps;
    if (!selectedItems.length) return;
    const selectedItem = selectedItems[selectedItems.length - 1];
    setSelectedItem(selectedItem);
  }, [collectionProps.selectedItems]);

  const closeDBModal = () => {
    setDatabaseResourceLinkModalVisible(false);
    handleRefresh();
  };
  const closeDSModal = () => {
    setDatasetResourceLinkModalVisible(false);
    handleRefresh();
  };
  return (
    <>
      <Flashbar items={notifications}></Flashbar>
      {redirect && <Redirect push to={redirect} />}
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
          },
          {
            text: 'Actions',
            onItemClick: handleButtonAction,
            loading: loadingButton || deactivateRequestLoading,
            items: [
              {
                text: 'Audit data permission',
                id: 'audit',
              },
              {
                text: 'Deactivate data permission',
                id: 'deactivate',
                disabled: dataPermission?.status === 'InActive',
              },
            ],
          },
        ]}
        header={`Dataset Permission ID: ${props.match.params.permissionId}`}
      />
      <Table
        {...collectionProps}
        loadingText='Loading permission detail...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Create resource link',
                  onItemClick: createResourceLink,
                  disabled:
                    collectionProps.selectedItems[0] == undefined ||
                    collectionProps.selectedItems[0].sourceResource !== undefined,
                },
              ]}
              header={
                <>
                  Shared data
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        selectionType='single'
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No shared data</p>
          </div>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingResource}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
      <br />

      {resourceLinkList !== undefined && <ResourceLinkTable {...props} resourceLinkList={resourceLinkList} />}
      <DatabaseResourceLink
        {...props}
        visible={databaseResourceLinkModalVisible}
        notifyCreateResourceLinkSuccess={notifyCreateResourceLinkSuccess}
        notifyCreateResourceLinkFailure={notifyCreateResourceLinkFailure}
        closeModal={closeDBModal}
        database={selectedItem}
      />
      <TableResourceLink
        {...props}
        visible={datasetResourceLinkModalVisible}
        notifyCreateResourceLinkSuccess={notifyCreateResourceLinkSuccess}
        notifyCreateResourceLinkFailure={notifyCreateResourceLinkFailure}
        closeModal={closeDSModal}
        dataset={selectedItem}
      />

      <br />
      {dataPermission != null && dataPermissionDetail(dataPermission)}

      <br />
      {dataPermission && dataPermission?.audit?.RamResourceShare !== null && (
        <DataPermissionRAMResourceShare
          ramResourceShare={
            dataPermission['audit']['RamResourceShare'] !== undefined
              ? JSON.parse(dataPermission['audit']['RamResourceShare'])[0]
              : undefined
          }
        />
      )}
      {dataPermission && dataPermission?.audit?.RamResources !== null && (
        <DataPermissionRAMResources
          ramResources={
            dataPermission['audit']['RamResources'] !== undefined
              ? JSON.parse(dataPermission['audit']['RamResources'])
              : []
          }
        />
      )}
      <Modal
        onDismiss={() => setDeactivateModalVisible(false)}
        visible={deactivateModalVisible}
        size='small'
        footer={
          <span className='awsui-util-f-r'>
            <Button
              variant='link'
              onClick={() => {
                setDeactivateModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={handleDeactivate} loading={deactivateRequestLoading}>
              Confirm
            </Button>
          </span>
        }
        header={'Please provide the reason for deactivating.'}
      >
        <Input
          placeholder='Please provide your reason.'
          onChange={(e) => setDeactivateReason(e.detail.value)}
          value={deactivateReason}
        />
      </Modal>
    </>
  );
};
