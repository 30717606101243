import * as React from 'react';
import { useState, useEffect } from 'react';

import { defaultPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Header,
  SpaceBetween,
  Box,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { DZEntity } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { createWorkflowStatusLink } from 'src/routes';

export interface DataZoneAccountsTableProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  nextToken?: string;
  loadMoreResults?: any;
  loading: boolean;
  accountEntities: DZEntity[];
}

export const DataZoneAccountsTable = (props: DataZoneAccountsTableProps) => {
  const [tableMessage] = useState('No accounts');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const columnDefinitions: TableProps.ColumnDefinition<DZEntity>[] = [
    {
      id: 'accountId',
      header: 'Account ID',
      cell: (item) => item.accountId,
      width: 200,
      sortingField: 'accountId',
    },
    {
      id: 'workflowId',
      header: 'Workflow ID',
      cell: (item) =>
        item.workflowConfig && item.workflowConfig.onboardWorkflowId ? (
          <Link to={createWorkflowStatusLink(item.workflowConfig.onboardWorkflowId)}>
            {' '}
            {item.workflowConfig.onboardWorkflowId}{' '}
          </Link>
        ) : (
          ''
        ),
      width: 330,
      sortingField: 'workflowId',
    },
    {
      id: 'ramShareArn',
      header: 'Resource share ARN',
      cell: (item) => item.accountMetadata.ramShareArn,
      width: 500,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    props.accountEntities,
    {
      filtering: {
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No accounts to display.</p>
          </div>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading accounts...'
        loading={props.loading}
        columnDefinitions={columnDefinitions}
        items={props.accountEntities}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='m'>
              <b>No accounts</b>
              <p>Accounts are associated when onboarding an environment.</p>
            </SpaceBetween>
          </Box>
        }
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <Header counter={'(' + props.accountEntities.length + ')'}>
            Accounts associated with Omni-DataZone domain
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
